import React, { useEffect, useState } from 'react';
import './App.css';
import { AuthenticationService } from './services/AuthenticationService';
import Settings from './components/settings/settings';
import NavBar from './components/navbar/navbar';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import {Cookie} from './utils/cookie';

localStorage.setItem('editMode', 'false');

const App = () => {
  const authenticationService = AuthenticationService.getInstance();
  const [userIsConnected, setUserIsConnected]: any = useState(false);

  useEffect(() => {
    let searchParamsUrl = window.location.search;
    const tokenCookie = Cookie.get('token');
    if (!userIsConnected) {
      let tokens: any = searchParamsUrl.split('?refreshToken=');
      if (tokens && tokens.length === 2) {
        const token: any = tokens[0].replace('?token=', '');
        const refreshToken: any = tokens[1];
        authenticateUserByToken(decodeURIComponent(token));
        authenticationService.setRefreshToken(decodeURIComponent(refreshToken));
      } else {
        const token = authenticationService.authToken;
        if (token) {
          authenticateUserByToken(token);
        } else if (tokenCookie) {
          authenticateUserByToken(tokenCookie);
        } else {
          authenticationService.logout();
        }
      }
    }
  }, []);

  const refreshTokenWhenExpired = () => {
    const now = new Date();
    const newTime = authenticationService.getTokenExpiration().getTime() - now.getTime();
    setTimeout(() => {
      authenticationService.refreshToken();
    }, newTime);
  };

  const authenticateUserByToken = (token: string) => {
    authenticationService.authenticateByToken(token)
      .then((data: any) => {
        setUserIsConnected(authenticationService.isAuthenticated());
        window.history.replaceState(null, '', '/');
        refreshTokenWhenExpired();
      })
      .catch((error: any) => {
        returnToBackPage();
      });
  };

  const returnToBackPage = () => {
    window.history.go(-1);
  };

  return (
    <>
      {userIsConnected &&
        <Box style={{ width: "100%", display: 'flex', flexDirection: 'column' }} key={uuidv4()}>
          <NavBar></NavBar>
          <Settings></Settings>
        </Box>
      }
    </>
  );
};

export default App;
