import React, { useEffect, useState } from 'react';
import { TitleTypography } from '../styled-components/title-typography';
import { Box } from '@mui/material';
import { CustomButton } from '../styled-components/custom-button';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import './custom-link-sent.scss';

const CustomLinkSent = (props: any) => {

    const [open, setOpen] = useState(props.open);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.keyCode === 13) { //Enter key
                event.preventDefault();
                props && props.onChange && props.onChange('login');
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const generateTitleTypography = (text: string, style?: any) => {
        return (<TitleTypography
            key={`title-${Math.random()}`}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...{ ...style } }} >
            {text}
        </TitleTypography>);
    }

    const generateSubTitleTypography = (text: string, color?: any, style?: object) => {
        const commonStyle = {
            justifyContent: 'flex-start',
            fontFamily: 'ft-system-regular',
            fontSize: '14px',
            color: color ?? '#344054',
            fontWeight: '400',
            ...style
        };
        return generateTitleTypography(text, commonStyle);
    }

    return (
        <Modal
            className="centerModal"
            disablePortal={true}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
            disableEscapeKeyDown={true}
        >
            <Box className='modal'>
                {generateTitleTypography('Password reset link sent!', { marginBottom: '20px' })}
                {generateSubTitleTypography("Please check your email to continue the process. Don't forget to check your spam/junk folder too.", '#667085', { textAlign: 'left' })}
                {generateSubTitleTypography("In case that no email arrives in a while could be because no account is linked to the email sent", '#667085', { marginBottom: '20px', textAlign: 'left' })}
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
                    <a style={{ color: '#667085' }} target='_blank' href={'' + process.env.REACT_APP_CONTACT_AIZ_WEB}>
                        {generateSubTitleTypography("Haven't received the email? Contact us!", '#667085')}
                    </a>
                    <CustomButton
                        onClick={(e: any) => {
                            props && props.onChange && props.onChange('close');
                        }}
                        children='Close'
                    />
                </Box>
            </Box>
        </Modal>
    )
}

CustomLinkSent.propTypes = {
    open: PropTypes.bool,
    changeOpen: () => false,
    onChange: () => false,
}

CustomLinkSent.defaultProps = {
    open: false,
    changeOpen: () => false,
    onChange: () => false,
}

export default CustomLinkSent