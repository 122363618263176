import { ReactNode, useEffect, useState } from 'react';
import { Alert, Box, Snackbar } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { TitleTypography } from '../components/styled-components/title-typography';
import { CustomButton } from '../components/styled-components/custom-button';
import LinearProgressWithLabel from '@mui/material/LinearProgress';
import { AuthenticationService } from '../services/AuthenticationService';
import CustomTable from '../components/account-administrator/table/custom-table';
import { formatISOtoDate, formatValue } from './utils';
import { ACCOUNT_MEMBERS } from '../components/commons/constants';
import { PaymentService } from '../services/PaymentService';
import CustomNotification from '../components/commons/notification/custom-notification';
import { Plan } from '../models/Plan';

type Restriction = {
    header: string,
    description: string,
    key: string,
    defaultValue: boolean | string | number,
}

type Footer = {
    style?: object,
    text: string,
    onClick: Function
}

type SectionData = {
    title: string,
    subtitle?: string | any[],
    titleComplement?: string,
    type?: string,
    body?: { type: string, titleComplement?: string | number, data?: string | any[], style?: Object },
    sections?: any[],
    footer?: Footer[]
    style?: Object
}

const SECTION_TYPES = {
    FLEX: 'flex',
    BLOCK: 'block',
    TABLE: 'table',
    TEXT: 'text',
    PROGRESS: 'progress',
}

type AlertMessage = {
    open: boolean,
    message: string,
}

const Payment = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const paymentService = PaymentService.getInstance();
    const subscriptionInfo = authenticationService.subscriptionInfo;
    const status = authenticationService.subscriptionStatus;
    const isOwner = authenticationService.isOwner;

    const plan = subscriptionInfo?.asset || null;
    const [planData] = useState<Plan | null>(plan);
    const [subSections, setSubSections] = useState<SectionData[]>([]);
    const [restrictions] = useState(authenticationService.restrictionsInfo);
    const [numUsers, setNumUsers] = useState<any>();
    const [maxUsers, setMaxUsers] = useState<any>();
    const [expirationDate, setExpirationDate] = useState<string | boolean>();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ open: false, message: '' });

    useEffect(() => {
        if (restrictions && !maxUsers && !numUsers) {
            const accountMemberRestriction = restrictions.find((rest) => rest.key === ACCOUNT_MEMBERS);
            getAmmountAccountsUsers();
            setMaxUsers(accountMemberRestriction);
        }
    }, [restrictions]);

    useEffect(() => {
        if (planData && subscriptionInfo?.expirationDate && !expirationDate) {
            const formatedExpirationDate = formatISOtoDate(subscriptionInfo.expirationDate);
            setExpirationDate(formatedExpirationDate);
        }
    }, [planData]);

    const getAmmountAccountsUsers = () => {
        authenticationService.getAccountsWithUsers().then((response: any) => {
            if (response && response.data && response.data.length > 0) {
                setNumUsers(response.collectionSize ?? 0);
            } else {
                throw Error('Could not get users');
            }
        });
    }

    const buildSubSectionByType = ({ title, type, subtitle, titleComplement, body, footer, sections, style }: SectionData) => {
        return {
            title,
            subtitle,
            titleComplement,
            type,
            body,
            footer,
            sections,
            style,
        }
    }



    const formatRestrictions = (restrictions: Restriction[]) => {
        const restrictionsTableData: any = {};
        restrictions.forEach((restriction: Restriction) => {
            const { header, description, defaultValue } = restriction;
            const keyExists = restrictionsTableData[header as keyof typeof restrictionsTableData];
            if (keyExists) {
                keyExists?.components.push({ name: description, role: ('' + formatValue(defaultValue)) });
            } else {
                restrictionsTableData[header as keyof typeof restrictionsTableData] = {
                    name: header,
                    components: [
                        {
                            name: description,
                            role: ('' + formatValue(defaultValue))
                        }
                    ]
                }
            }
        });
        return Object.values(restrictionsTableData);
    }

    const handleFinishAlertMessage = () => {
        setAlertMessage({ open: false, message: '' })
    }

    const handleUpgrade = () => {
        paymentService.updateSubcriptionPlan()
            .then(() => {
                setAlertMessage({ open: true, message: 'Your upgrade request has been sent. Our representative will contact you shortly.' })
            })
            .catch((err) => {
                setAlertMessage({ open: true, message: 'There has been an error while trying to send the request please try again later' })
                console.error(err);
            })
    }

    const buildSections = (section: Plan) => {
        const planPrice = '$' + (section.priceMonthly || section.priceYearly);
        const expiration = expirationDate && ('Expiry ' + expirationDate);
        const planflexedSection = buildSubSectionByType({
            title: section.name,
            subtitle: [expiration, status],
            titleComplement: planPrice,
            body: {
                type: SECTION_TYPES.PROGRESS,
                data: numUsers
            },
            ...(isOwner && { footer: [{ text: 'Upgrade plan', onClick: handleUpgrade }] })
        });
        const planSubSection = buildSubSectionByType({
            type: SECTION_TYPES.FLEX,
            title: 'Account plan',
            subtitle: 'Manage your billing and payment details',
            sections: [
                planflexedSection
            ],
        });
        if (restrictions && restrictions.length) {
            const restrictionValues = formatRestrictions(restrictions);
            const susbcriptionSubSection = buildSubSectionByType({
                type: SECTION_TYPES.BLOCK,
                title: 'Subscription plan features',
                body: {
                    type: SECTION_TYPES.TABLE,
                    data: restrictionValues,
                    style: {
                        marginTop: '1rem'
                    }
                }
            });
            return setSubSections((prevState) => [...prevState, planSubSection, susbcriptionSubSection]);
        }
        return setSubSections((prevState) => [...prevState, planSubSection]);
    }

    const generateBox = (BoxComponent: any, style?: any) => {
        return (<Box sx={style} key={uuidv4()}>
            {BoxComponent}
        </Box>);
    }

    const generateText = (text: string, className: string = '') => {
        return <TitleTypography className={className} >
            {text}
        </TitleTypography>
    }

    const buildHeaderTitle = ({ title, subTitle }: { title: ReactNode, subTitle?: ReactNode }, className?: string) => {
        return <Box className={className}>
            {generateBox(title)}
            {subTitle && generateBox(subTitle)}
        </Box>
    }

    const buildHeaderSubtitle = (subtitle: any[] | string, className?: string) => {
        if (Array.isArray(subtitle)) {
            return subtitle.map((sbtitl: string) => generateText(sbtitl, className + 'tinyText'));
        } else if (typeof subtitle === 'string') {
            return generateText(subtitle, className + 'tinyText');
        }
    }

    const buildTitleComplement = (titleComplement: string) => {
        return <Box className='titleText'>{titleComplement}</Box>
    }

    const buildSectionHeader = (headerData: SectionData) => {
        const headersDisplayType = 'displayFlex alignCenter justifyContentStart ';
        const title = generateText(headerData.title, headersDisplayType + 'regularText');
        let subTitle;
        if (headerData?.subtitle) {
            subTitle = buildHeaderSubtitle(headerData?.subtitle, headersDisplayType);
        }
        if (headerData.titleComplement) {
            return <Box className='title'>
                <Box className='displayFlex spaceBetween' >
                    {buildHeaderTitle({ title, subTitle })}
                    {buildTitleComplement(headerData.titleComplement)}
                </Box>
            </Box>
        }
        return buildHeaderTitle({ title, subTitle }, 'title');
    }

    const buildSectionBody = (body: any) => {
        if (body.type === SECTION_TYPES.TEXT && body.data) {
            return <Box className='sectionBody'>{body.data}</Box>
        } else if (body.type === SECTION_TYPES.TABLE && Array.isArray(body.data)) {
            return <CustomTable headers={[]} data={body.data}></CustomTable>
        } else if (body.type === SECTION_TYPES.PROGRESS && body.data) {
            const minProgress = 0;
            const maxProgress = maxUsers.defaultValue
            const normalise = (value: number) => ((value - minProgress) * 100) / (maxProgress - minProgress);

            return <Box className='sectionBody displayFlex'>
                <Box className='progressStyle'>
                    <LinearProgressWithLabel value={normalise(body.data)} variant="determinate" />
                </Box>
                <span style={{ marginLeft: '15px' }} className='tinyText'>{`${body.data} of ${maxProgress} users`}</span>
            </Box>
        }
    }

    const buildSectionFooter = (footer: Footer[]) => {
        return footer.map((ft: Footer) => {
            return <CustomButton className='btnWhite' style={ft.style} onClick={() => ft.onClick()}>
                {ft.text}
            </CustomButton>
        })
    }

    const formatSections = (section: SectionData) => {
        let title, body, footer = null;
        if (section && Object.keys(section).length) {
            title = buildSectionHeader(section);
        }
        if (section?.body) {
            body = buildSectionBody(section.body);
        }
        if (section?.footer) {
            footer = buildSectionFooter(section.footer);
        }
        const formatedSection = !!title || !!body || !!footer;
        if (formatedSection) {
            return <Box style={section?.style} className='section'>
                {title}
                {body && (<Box style={section.body?.style} className='body'>{body}</Box>)}
                {footer && (<Box className='footer'>{footer}</Box>)}
            </Box>
        }
        return null;
    }

    const generateSections = () => {
        if (!subSections.length && planData && maxUsers && numUsers) {
            buildSections(planData);
        } else {
            const sectionFormat: any[] = [];
            subSections.forEach((sectionData: any) => {
                sectionFormat.push(formatSections(sectionData));
                if (sectionData?.type === SECTION_TYPES.FLEX && Array.isArray(sectionData?.sections)) {
                    const selectionSections = sectionData.sections.map(formatSections)
                    sectionFormat.push(<Box className={SECTION_TYPES.FLEX}>{selectionSections}</Box>);
                }
            });
            return sectionFormat;
        }
    }

    return (
        <Box className='displayFlex displayColumn'>
            <CustomNotification message={alertMessage.message} open={alertMessage.open} onClose={handleFinishAlertMessage} />
            {generateBox(generateText(props.title, 'displayFlex alignCenter justifyContentStart titleText'))}
            {generateBox(generateSections(), { marginTop: '24px' })}
        </Box>
    );
}
export default Payment;