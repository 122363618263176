import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';
import { TitleTypography } from '../styled-components/title-typography';

const Input = React.forwardRef((props: any, ref: any) => {

    const [errorMessage, setErrorMessage] = React.useState(false);

    const classes = {
        textfield: {
            width: '100%',
            lineHeight: '125%',
            '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                fontFamily: 'ft-system-regular',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '20px',
                letterSpacing: '-0.01em',
                color: '#101828',
                '&.Mui-focused fieldset': {
                    borderColor: '#A82AAB',
                    boxShadow: '0px 0px 0px 4px rgba(168, 42, 171, 0.3), 0px 1px 2px rgba(16, 24, 40, 0.05)',
                },
            },
        }
    };

    const generateErrorMessage = (errorMessage: string) => {
        return <TitleTypography
            style={{
                display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                fontSize: '13px', color: 'red', fontFamily: 'ft-system-regular', marginTop: '5px'
            }} >
            {errorMessage}
        </TitleTypography>
    }

    return (
        <>
            <TextField
                inputRef={ref}
                id={props?.id ?? uuidv4()}
                label={props?.label}
                variant={props?.variant}
                defaultValue={props?.defaultValue}
                onChange={e => {
                    e.preventDefault();
                    setErrorMessage(false);
                    const value = e?.target?.value?.trim();
                    if (props.regex) {
                        const testValue = props.regex.test(e?.target?.value?.trim());
                        if (value && testValue) {
                            props.onChange(value);
                        } else if (!testValue) {
                            setErrorMessage(true);
                            props.onChange(undefined);
                        } else {
                            props.onChange(undefined);
                        }
                    } else if (!props.regex) {
                        props.onChange(value);
                    }
                }}
                sx={props?.style || classes.textfield}
                type={props?.type}
                inputProps={{
                    maxLength: props?.maxLength,
                    pattern: props?.regex
                }}
                classes={props?.style}
                placeholder={props?.placeholder}
                disabled={props?.disabled}
            />
            {errorMessage && generateErrorMessage(props.errorMessage)}
        </>
    );
});

Input.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['text', 'number', 'password']),
    maxLength: PropTypes.number,
    style: PropTypes.any,
    placeholder: PropTypes.string,
    regex: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
}

Input.defaultProps = {
    variant: 'outlined',
    onChange: () => { },
    type: 'text',
    disabled: false
}

export default Input