import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TitleTypography } from '../../components/styled-components/title-typography';
import { Box } from '@mui/system';
import { v4 as uuidv4 } from 'uuid';
import CustomTable from '../../components/account-administrator/table/custom-table';
import { AuthenticationService } from '../../services/AuthenticationService';
import { User } from '../../models/User';
import { AccountMemberRow } from '../../models/AccountMemberRow';
import { AccountMemberHeader } from '../../models/AccountMemberHeader';
import { ExposeService } from '../../services/ExposeService';
import { CustomButton } from '../../components/styled-components/custom-button';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { Restriction } from '../../models/Restriction';
import CustomModal from '../../components/modal/custom-modal';
import { Alert, AlertColor, Pagination, Snackbar, Box as MBox, Modal, Typography, Button } from '@mui/material';
import './management.scss';
import StandardModal from '../../components/modal/standard-modal';

interface PaginationStatus {
    actual: Number;
    total: Number;
}

const Management = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const exposeService = ExposeService.getInstance();
    const account = authenticationService.accountInfo;
    const user = authenticationService.userInfo;
    const [dataTable, setDataTable]: any[] = useState(undefined);
    const [usersCollectionSize, setUsersCollectionSize] = useState(0);
    const [modelsUsers, setModelsUsers]: any[] = useState(undefined);
    const [showAddButton, setShowAddButton] = useState(false);
    const [disableAddButton, setDisableAddButton] = useState(true);
    const [openAddNewMemberModal, setOpenAddNewMemberModal] = useState(false);
    const [removeUserId, setRemoveUserId] = useState('');
    const [removeUsername, setRemoveUsername] = useState('');
    const [admin, setAdmin]: any[] = useState(undefined);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [paginate, setPaginate]: any = useState({ actual: 0, total: 0 });
    const [snackBar, setSnackBar] = React.useState<{
        status: AlertColor;
        open: boolean;
        message: string;
      }>({ status: 'info', open: false, message: '' });

    useEffect(() => {
        if (!modelsUsers) {
            exposeService.getModelsUsers()
                .then((response: any) => {
                    if (response && response.length > 0) {
                        setModelsUsers(response);
                    } else {
                        setModelsUsers([]);
                    }
                }).catch((error: any) => {
                    console.error(error);
                    setModelsUsers([]);
                });
        }
    }, [exposeService, modelsUsers]);

    useEffect(() => {
        setShowAddButton(false);
        setDisableAddButton(true);
        if (account && dataTable) {
            if (user && account.ownerUserId === user.id) {
                setShowAddButton(true);
                const accountMembersRestriction: Restriction | undefined = account.restrictions
                    .find((rest: Restriction) => rest.key === 'account_members');
                if (accountMembersRestriction && accountMembersRestriction.defaultValue
                    && typeof accountMembersRestriction.defaultValue === 'number'
                    && usersCollectionSize < accountMembersRestriction.defaultValue) {
                    setDisableAddButton(false);
                }
            }
        }
    }, [account, dataTable]);

    useEffect(() => {
        if (!dataTable && modelsUsers) {
            getAccountsUsersData(1);
        }
    }, [modelsUsers, dataTable]);

    const getAccountsUsersData = (page: number) => {
        authenticationService.getAccountsWithUsers(page).then((response: any) => {
            if (response && response.data && response.data.length > 0) {
                const users = generateDataTable(response.data, modelsUsers);
                const flatUsers = orderUsersByRole(users);
                setDataTable(flatUsers);
                if (paginate.total === 0) {
                    setPaginate({ actual: page, total: Math.ceil(response.collectionSize / response.data.length) });
                } else if (page) {
                    setPaginate((prevPaginate: PaginationStatus) => {
                        return { ...prevPaginate, actual: page }
                    });
                }
                setUsersCollectionSize(response.collectionSize ?? 0);
            } else {
                setDataTable([]);
                setUsersCollectionSize(0);
                setPaginate({ actual: 0, total: 0 });
            }
        });
    }

    const orderUsersByRole = (users: AccountMemberRow[]) => {
        const ownerUsers = users.filter((user: AccountMemberRow) => user.role === 'Admin');
        const normalUsers = users.filter((user: AccountMemberRow) => user.role !== 'Admin');
        const newUsers = [ownerUsers, normalUsers].flat();
        return newUsers;
    }

    const generateTitleDescriptionPage = () => {
        const title = generateTitleTipography(props.title, undefined, uuidv4());
        const description = generateTitleTipography(
            'Manage your team members and their accounts here.',
            {
                fontSize: '18px', fontFamily: 'ft-system-regular', color: '#667085', marginTop: '12px',
                lineHeight: '160%', letterSpacing: '-0.015em'
            }, uuidv4());
        const BoxComponent = generateBox(undefined, [title, description]);
        return BoxComponent;
    }

    const generateMembersMainSection = () => {
        const title = generateTitleTipography('Members', { fontSize: '18px', }, uuidv4());
        const description = generateTitleTipography(
            'Manage your existing team and edit their information. ',
            {
                fontSize: '14px', fontFamily: 'ft-system-regular', color: '#667085',
                lineHeight: '20px', letterSpacing: '-1%'
            }, uuidv4());
        const TitleDescriptionBox = generateBox({ width: '80%' }, [title, description]);
        const componentsBox = [TitleDescriptionBox];
        if (showAddButton) {
            const buttonAction = () => { setOpenAddNewMemberModal(true) };
            const addButton = {
                key: 'add-button', text: 'Add team member', icon: AddIcon,
                className: disableAddButton ? 'disabled' : '', action: buttonAction,
                style: { width: '195px' }
            };
            const addMemberButton = generateButton(addButton);
            const addMemberButtonBox = generateBox(null, [addMemberButton]);
            componentsBox.push(addMemberButtonBox);
        }
        const titleWithButtonBox = generateBox({ display: 'flex', justifyContent: 'space-between' }, componentsBox);
        const table = generateBoxWithTable();
        const BoxComponent = generateBox({ marginTop: '2em' }, [titleWithButtonBox, table]);
        return BoxComponent;
    }

    const generateButton = (button: any) => {
        return <CustomButton
            key={button?.key}
            style={button?.style}
            onClick={() => {
                if (!disableAddButton) {
                    button?.action && button?.action();
                }
            }}
            className={`btnWhite ${button?.className}`}
        >
            {button?.icon && generateSvg(button.icon)}
            {button?.text}
        </CustomButton>;
    }

    const generateSvg = (Icon: any) => {
        return (<Icon style={{ marginLeft: '5px' }} />);
    }

    const generateDataTable = (users: User[], modelsUsers: any[]): AccountMemberRow[] => {
        let newUsers: AccountMemberRow[] = [];
        users.forEach(user => {
            const id = user?.id;
            const name = user?.firstName + ' ' + user?.lastName;
            const email = user?.email;
            const userModels: any = modelsUsers?.find((userModel: any) => userModel.userId === user.id);
            let models = userModels && userModels?.collectionSize ? userModels?.collectionSize : 0;
            let role = 'User';
            if (user.account && account && account.id && account.ownerUserId
                && user.account === account.id && user.id === account.ownerUserId) {
                role = 'Admin';
                setAdmin(name);
            }
            const accountRowUser = new AccountMemberRow(id, { title: name, description: email }, role, models);
            newUsers.push(accountRowUser);
        });
        return newUsers;
    }

    const generateBoxWithTable = () => {
        let headers: AccountMemberHeader[] = [];
        ['Name', 'Role', 'Models'].forEach((header: any) => headers.push(new AccountMemberHeader(header.toLowerCase(), header)));
        const deleteRows = account && user && account.ownerUserId === user.id;
        const table = (<CustomTable
            headers={headers}
            data={dataTable}
            deleteRows={deleteRows}
            deleteAction={(id: string) => {
                    const name = dataTable.find((user: AccountMemberRow) => user.id === id)?.name?.title;
                    setRemoveUsername(name);
                    setRemoveUserId(id);
                    setOpenRemoveModal(true);
                }
            }
        />);
        const BoxComponent = generateBox({ marginTop: '1em' }, [table]);
        return BoxComponent;
    }

    const removeUserToAccount = (id: string) => {
        const response = authenticationService.removeUserAccount(id);
        response.then(() => {
            setModelsUsers(undefined);
            setDataTable(undefined);
            setSnackBar({
                status: 'success',
                open: true,
                message: 'User has been removed and their assets have been transferred to the owner!'
              });
        }).catch((error) => {
            console.error(error)
            setSnackBar({
                status: 'error',
                open: true,
                message: 'Error removing user from account!'
              });
        });
    }

    const handlePagination = (page: number) => {
        getAccountsUsersData(page);
    }

    const generateBox = (style: any, BoxComponent: any) => {
        return (<Box sx={style} key={uuidv4()}>
            {BoxComponent}
        </Box>);
    }

    const generateTitleTipography = (title: string, style?: any, key?: any) => {
        return (<TitleTypography
            key={key}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '40px', ...{ ...style } }} >
            {title}
        </TitleTypography>);
    }

    const closeModal = (): void => {
        setOpenRemoveModal(false);
    };

    const removeUser = (): void => {
        removeUserToAccount(removeUserId);
        closeModal();
    };
    
      const headerComponent = (
        <MBox className='delete-modal-header'>
          <Typography className="delete-modal-title-header">
            {`Are you sure you want to delete user ${removeUsername}?`}
          </Typography>
        </MBox>
      );
    
      const bodyComponent = (
        <Box className='delete-modal-body'>
          <Typography className="delete-modal-body-title">
            {`All assets owned by ${removeUsername} will be transferred to the owner of the account ${admin}.`}
          </Typography>
        </Box >
      );

      const footerComponent = (
        <Box className='delete-modal-footer'>
          <Button
            className='close-modal-button'
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="delete-modal-button"
            style={{ borderColor: '#D0D5DD' }}
            onClick={removeUser}
          >
            Yes, delete
          </Button>
        </Box>
      );

    return (
        <>
            {generateTitleDescriptionPage()}
            {generateMembersMainSection()}
            <Box sx={{ marginTop: '1em', display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination
                    onChange={(event: React.ChangeEvent<unknown>, page: number) => handlePagination(page)}
                    className='pagination'
                    count={paginate.total}
                    variant='outlined'
                    shape='rounded'
                    showFirstButton
                    showLastButton
                />
            </Box>
            {openAddNewMemberModal &&
                <CustomModal
                    open={openAddNewMemberModal}
                    changeOpen={setOpenAddNewMemberModal}
                    onChange={(e: any) => {
                        setOpenAddNewMemberModal(false);
                        if (e && e === 'success') {
                            setModelsUsers(undefined);
                            setDataTable(undefined);
                        }
                    }}
                />}
            {openRemoveModal &&
                <Modal
                    className='center-modal'
                    disablePortal={true}
                    open={true}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                    disableAutoFocus
                    disableEscapeKeyDown={true}
                >
                    <Box className='delete-modal-wrapper'>
                        {headerComponent}
                        {bodyComponent}
                        {footerComponent}
                    </Box>
                </Modal>
                }
            <Snackbar
                open={snackBar.open}
                autoHideDuration={10000}
                onClose={() => {
                setSnackBar({ status: 'info', open: false, message: '' });
                }}
            >
                <Alert severity={snackBar.status}>{snackBar.message}</Alert>
            </Snackbar>
        </>
    );
}

Management.propTypes = {
    title: PropTypes.string.isRequired,
}

export default Management;