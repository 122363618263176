import React from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { OutlinedInput } from '@mui/material';
import PropTypes from 'prop-types';

const classes = {
    input: {
        width: '100%',
        lineHeight: '125%',
        borderRadius: '8px',
        fontFamily: 'ft-system-regular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        letterSpacing: '-0.01em',
        color: '#101828',
        '&.Mui-focused fieldset': {
            borderColor: '#A82AAB !important',
            boxShadow: '0px 0px 0px 4px rgba(168, 42, 171, 0.3), 0px 1px 2px rgba(16, 24, 40, 0.05)',
        },
    }
};

const InputPassword = (props: any) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show)
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onChangeValue = (event: any) => {
        event.preventDefault();
        props && props?.changeValue && props?.changeValue(event.target.value);
    }

    return (
        <OutlinedInput
            key={props?.keys}
            type={showPassword ? 'text' : 'password'}
            sx={classes.input}
            onChange={onChangeValue}
            inputProps={{ minLength: props?.minLength ?? 0 }}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ color: '#98A2B3', '&:hover': { color: '#A82AAB' } }}
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }
        />
    );
}

InputPassword.propTypes = {
    style: PropTypes.any,
    changeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    keys: PropTypes.any,
}

InputPassword.defaultProps = {
    changeValue: () => { }
}

export default InputPassword;