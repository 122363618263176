import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import './custom-modal.scss';
import { generateWhiteButton, generateTitleTipography } from '../../pages/utils';

const classes = {
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        width: '498px',
        userSelect: 'none',
        background: '#FFFFFF',
        borderRadius: '10px',
    }
};

const StandardModal = (props: any) => {
    return (
        <Modal
            className='centerModal'
            disablePortal={true}
            open={props.open}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            disableAutoFocus
        >
            <Box sx={classes.box}>
                {props.title && generateTitleTipography(props.title, { fontSize: '20px', fontFamily: 'ft-system-bold' })}
                {props.message && generateTitleTipography(props.message, { fontSize: '16px', fontFamily: 'ft-system-regular' })}
                {props.buttons?.length && props.buttons.map(generateWhiteButton)}
            </Box>
        </Modal >
    );
}

StandardModal.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    buttons: PropTypes.array
}

StandardModal.defaultProps = {
    open: false,
}

export default StandardModal;