import './custom-button.scss';
export const CustomButton = (props: any) => {
    const className = props.className
        ? 'CustomButton ' + props.className
        : 'CustomButton';

    return <button
        onClick={props.onClick}
        key={props.keys}
        className={className}
        style={props.style}
        disabled={props.disabled}
    >
        {props.children}
    </button>;
};
