import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar, { ToolbarProps } from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AIZWEILOGO from '../../assets/imgs/aizwei-logo.svg';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import { CustomButton } from '../styled-components/custom-button';
import { v4 as uuidv4 } from 'uuid';
import AccountAdministrator from '../account-administrator/account-administrator';
import { AuthenticationService } from '../../services/AuthenticationService';

const StyledToolbar = styled(Toolbar)<ToolbarProps>(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '20px !important',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), rgba(0, 0, 0, 0.08)',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
    width: '100%',
    display: 'flex',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#667085'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
        height: '24px',
        fontFamily: 'ft-system-regular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.02em',
        color: '#344054',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        userSelect: 'none',
    },
    width: '100%',
}));

const MARKETPLACE_BUTTONS = [
    {
        key: uuidv4(), text: 'Explore',
        style: { maxWidth: '55px', width: '100%', borderColor: 'transparent', background: 'transparent', color: '#667085', margin: '10px' }
    },
    {
        key: uuidv4(), text: 'Browse',
        style: { maxWidth: '55px', width: '100%', borderColor: 'transparent', background: 'transparent', color: '#101828', margin: '10px' }
    }
];

const generateButtons = (buttons: any[]) => {
    return buttons.map((button: any) => {
        const Type = button?.type ?? CustomButton;
        return <Type
            key={button.key}
            style={button?.style}
            onClick={button?.action}
            {...button?.props}
        >
            {button.text}
        </Type>;
    });
}

//TODO: change nav and body remove position absolutes
const NavBar = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const user = authenticationService.userInfo;
    const PAGES_BUTTONS = [
        {
            key: uuidv4(), text: 'Assistant',
            type: CustomButton,
            style: { textTransform: 'none', margin: '10px', color: '#101828', background: 'transparent', border: 'none', fontSize: '16px' },
            action: () => openAssistant()
        }
    ];
    const ACCOUNT_BUTTONS = [
        {
            key: uuidv4(), text: user?.email, type: AccountAdministrator,
            props: { text: user?.email },
            style: { textTransform: 'none', margin: '10px', color: '#101828', background: 'transparent', border: '1px solid #D0D5DD' }
        },
        {
            key: uuidv4(), text: 'Create', style: { margin: '10px' }, type: CustomButton
        }
    ];

    const openAssistant = () => {
        const urlAssistant = '' + process.env.REACT_APP_ASSISTANT_FRONT_BASE_URL + '' + process.env.REACT_APP_ASSISTANT_FRONT_PREFIX_URI;
        window.location.href = urlAssistant;
    }

    return (
        <AppBar color='transparent' elevation={0} position='static'>
            <StyledToolbar
                sx={{
                    justifyContent: 'flex-start !important'
                }}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => props?.onChange()}
                    sx={{
                        mr: 2,
                        borderRadius: '10px !important',
                        "&:hover": {
                            borderRadius: '10px !important'
                        }
                    }}
                >
                    <img src={AIZWEILOGO} alt="AIZWEI LOGO" />
                </IconButton>
                {/* TODO: add later 
                {generateButtons(MARKETPLACE_BUTTONS)} 
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
                */}
                <div className='displayFlex' style={{ width: '100%', justifyContent: 'space-between' }}>
                    <div className='displayFlex'>
                        {generateButtons(PAGES_BUTTONS)}
                    </div>
                    <div className='displayFlex'>
                        {generateButtons(ACCOUNT_BUTTONS)}
                    </div>
                </div>
            </StyledToolbar>
        </AppBar>
    );
}

NavBar.propTypes = {
    onChange: PropTypes.func,
}

export default NavBar;