import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import './custom-table.scss';
import PropTypes from 'prop-types';

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StyledTableRow } from './commons';

const ComponentsRows = (props: any) => {
    const [open, setOpen] = useState<boolean>(true);
    const { components, name, role, style, arrow } = props;

    const componentOpener = components && <TableCell width="5%">
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
    </TableCell>

    const rowInnerData = components && <StyledTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                    <Table size="small" aria-label={`${name}`}>
                        <TableBody>
                            {components.map((comp: any, index: number) => {
                                const styleLast = (components.length - 1) === index ? {} : { borderBottom: "1px solid rgba(224, 224, 224, 1)" };
                                return (
                                    <StyledTableRow style={styleLast}>
                                        <TableCell width="80%">{comp.name}</TableCell>
                                        <TableCell width="20%">{comp.role}</TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Box>
            </Collapse>
        </TableCell>
    </StyledTableRow>

    return (
        <React.Fragment>
            <StyledTableRow style={{ background: "#F9FAFB" }}>
                {!arrow && componentOpener}
                {name && <TableCell component="th" scope="row">{name}</TableCell>}
                {role && <TableCell style={style ?? { textAlign: "right" }}>{role}</TableCell>}
            </StyledTableRow>
            {rowInnerData}
        </React.Fragment>
    );
}

ComponentsRows.propTypes = {
    components: PropTypes.array.isRequired,
    name: PropTypes.string,
    role: PropTypes.string
}

export default ComponentsRows