import { useEffect, useState } from 'react';
import { TitleTypography } from '../styled-components/title-typography';
import { Box } from '@mui/material';
import Input from '../input/Input';
import { CustomButton } from '../styled-components/custom-button';
import { AuthenticationService } from '../../services/AuthenticationService';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import './custom-forgot-password.scss';

const CustomForgotPassword = (props: any) => {

    const authenticationService = AuthenticationService.getInstance();
    const [email, setEmail]: any = useState(null);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [enableEmailButton, setEnableEmailButton] = useState(false);
    const [errorMessage, setErrorMessage]: any = useState(null);
    const EMAIL_REGEX = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(_[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,}$';
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        if (enableEmailButton) {
            const keyDownHandler = (event: any) => {
                if (event.keyCode === 13) { //Enter key
                    event.preventDefault();
                    recoverPasswordToken();
                }
            };
            document.addEventListener('keydown', keyDownHandler);
            return () => {
                document.removeEventListener('keydown', keyDownHandler);
            };
        }
    }, [enableEmailButton]);

    useEffect(() => {
        setErrorMessage(null);
        if (email && email.trim() !== '') {
            setEnableEmailButton(true);
        } else {
            setEnableEmailButton(false);
        }
    }, [email])

    const addText = (newText: string, setText: any, type: any = 'email', regex?: any) => {
        if (regex && regex !== '') {
            const newRegex = new RegExp(regex);
            if (newRegex.test(newText)) {
                if (type === 'email') {
                    setInvalidEmail(false);
                }
                setText(newText);
            } else {
                if (type === 'email') {
                    setInvalidEmail(true);
                }
                setText('');
            }
        } else {
            setText(newText?.trim());
            if (type === 'email') {
                setInvalidEmail(true);
            }
        }
    }

    const generateTitleTypography = (text: string, style?: any) => {
        return (<TitleTypography
            key={`title-${Math.random()}`}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...{ ...style } }} >
            {text}
        </TitleTypography>);
    }

    const generateSubTitleTypography = (text: string, color?: any, style?: object) => {
        const commonStyle = {
            justifyContent: 'flex-start',
            fontFamily: 'ft-system-regular',
            fontSize: '14px',
            color: color ?? '#344054',
            fontWeight: '400',
            ...style
        };
        return generateTitleTypography(text, commonStyle);
    }

    const generateErrorMessage = (errorMessage: string) => {
        return generateTitleTypography(
            errorMessage,
            {
                justifyContent: 'flex-start',
                fontFamily: 'ft-system-regular', fontSize: '14px', color: '#F04438',
                fontWeight: '400'
            });
    }

    const generateInput = (inputType: string, placeholder: string, action: any, enterAction: Function) => {
        return (<Input
            type={inputType}
            placeholder={placeholder}
            onChange={action}
            enterAction={enterAction}
        />);
    }

    const recoverPasswordToken = () => {
        setErrorMessage(null);
        authenticationService.recoverPasswordToken(email).then(() => {
            props && props.onChange && props.onChange('link-sent');
        }).catch((error: any) => {
            console.log(error);
            setErrorMessage('The e-mail address introduced does not match any account');
            setInvalidEmail(true);
        })
    }

    return (
        <Modal
            className="centerModal"
            disablePortal={true}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
            disableEscapeKeyDown={true}
        >
            <Box className='modal'>
                {generateTitleTypography('Need a hand with your password?', { marginBottom: '20px' })}
                {generateSubTitleTypography("Let's reset it, please enter your email address. You will receive a link to create a new password via email.", '#667085', { marginBottom: '20px', textAlign: 'left' })}
                <Box style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', gap: '20px' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
                        {generateSubTitleTypography('Email')}
                        {generateInput('text', 'example@aizwei.com',
                            (e: any) => addText(e ? e : '', setEmail, 'email', EMAIL_REGEX),
                            () => recoverPasswordToken())}
                        {invalidEmail && generateErrorMessage('Please enter a valid email address' ?? errorMessage)}
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <CustomButton
                            disabled={!enableEmailButton}
                            className={!enableEmailButton ? 'disabled' : ''}
                            onClick={(e: any) => recoverPasswordToken()}
                            children='Request password reset'
                        />
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

CustomForgotPassword.propTypes = {
    open: PropTypes.bool,
    changeOpen: () => false,
    onChange: () => false,
}

CustomForgotPassword.defaultProps = {
    open: false,
    changeOpen: () => false,
    onChange: () => false,
}

export default CustomForgotPassword