import { Plan } from "./Plan";

export class Subscription {
    id: string;
    asset: Plan;
    accountId: string;
    status: number;
    expirationDate: string;
    creationDate: string;

    constructor(id: string, asset: Plan, accountId: string, status: number, expirationDate: string, creationDate: string) {
        this.id = id;
        this.asset = asset;
        this.accountId = accountId;
        this.status = status;
        this.expirationDate = expirationDate;
        this.creationDate = creationDate;
    }
}