export const ACCOUNT_MEMBERS = 'account_members';

export const RESTRICTION_FEATURES = {
    account_members: 'Users and resources',
    shared_resources: 'Users and resources',
    stored_models_account: 'Models',
    stored_models_user: 'Models',
    training_rows: 'Training data',
    training_columns: 'Training data',
    prediction_rows: 'Inference data',
    prediction_columns: 'Inference data',
    file_format: 'Integrations and reporting',
    reports: 'Integrations and reporting',
}
