import PropTypes from 'prop-types';
import { Box, Pagination } from '@mui/material';
import { createRef, useState, useRef } from 'react';
import { TitleTypography } from '../components/styled-components/title-typography';
import { AuthenticationService } from '../services/AuthenticationService';
import CustomTable from '../components/account-administrator/table/custom-table';
import { useEffect } from 'react';
import { PaymentService } from '../services/PaymentService';

import { ReactComponent as AddIcon } from '../assets/icons/add.svg';
import { generateBox, generateWhiteButton, standardButtonType, generateInput, generateTitleTipography } from './utils';
import { v4 as uuidv4 } from 'uuid';
import { Chip } from '@mui/material';
import { Plan } from '../models/Plan';
import './pages.scss';
import { Restriction } from '../models/Restriction';
import { formatValue, generateModal, generateStandardModal } from './utils';
import WarningModal from '../components/modal/warning-modal';

type PaginationStatus = {
    actual: number,
    total: number
}

const PlanManagement = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const paymentService = PaymentService.getInstance();
    const [plans, setPlans]: any = useState(null);
    const [structureTables, setStructureTables]: any = useState(null);
    const [disableAddButton, setDisableAddButton]: any = useState(false);
    const [paginate, setPaginate]: any = useState({ actual: 0, total: 0 });
    const [categorizedRestrictions, setCategorizedRestrictions]: any[] = useState(null);
    const [tableButtons, setTableButtons]: any = useState(null);
    const [visibleTableButtons, setVisibleTableButtons]: any = useState(false);
    const [itemsRef, setItemsRef]: any[] = useState([]);
    const [openInfoModal, setOpenInfoModal]: any[] = useState(false);
    const [openWarningModal, setOpenWarningModal]: any[] = useState(false);
    const [editHeaderId, setEditHeaderId]: any = useState(null);
    const [editableHeaders, setEditableHeaders]: any[] = useState(true);

    const standardButton = {
        className: 'allowed',
        text: 'OK',
        style: { width: '100%', marginTop: '2em' },
        action: () => {
            setOpenInfoModal(false);
        }
    }

    const addPlanModal: generateStandardModal = {
        open: true,
        title: 'Done!',
        message: 'A new plan has been added.',
        buttons: [standardButton]
    }

    const editPlanModal: generateStandardModal = {
        open: true,
        title: 'Done!',
        message: 'The plan was saved correctly.',
        buttons: [standardButton]
    }

    const failedPlanModal: generateStandardModal = {
        open: true,
        title: 'The plan was not saved!',
        message: 'Please check if all the fields are filled in properly.',
        buttons: [standardButton]
    }

    useEffect(() => {
        window.addEventListener('click', proxyCheckEditMode);
        return () => {
            window.removeEventListener('click', proxyCheckEditMode);
        }
    }, [])

    const proxyCheckEditMode = (event: any) => {
        const button = event.target;
        const editModeActive = localStorage.getItem('editMode');
        if (button.nodeName === 'BUTTON' && editModeActive === 'true' && !button.className.includes('allowed')) {
            event.stopPropagation();
            setOpenWarningModal(true);
        }
    }

    const buildPlans = (planResponse: any, page?: number) => {
        if (planResponse && planResponse.data && planResponse.data.length) {
            setDisableAddButton(false);
            setTableButtons(null);
            setVisibleTableButtons(false);
            setPlans(planResponse.data);
            if (paginate.total === 0) {
                setPaginate({ actual: 1, total: Math.ceil(planResponse.collectionSize / planResponse.data.length) });
            } else if (page) {
                setPaginate((prevPaginate: PaginationStatus) => {
                    return { ...prevPaginate, actual: page }
                });
            }
        } else {
            setPlans([]);
            setPaginate({ actual: 0, total: 0 });
        }
    }

    const buildTableStructures = () => {
        const newStructure = generateStructureTables();
        setStructureTables(newStructure);
    }

    useEffect(() => {
        if (!categorizedRestrictions) {
            authenticationService.getSystemRestriction()
                .then(data => {
                    setCategorizedRestrictions(data);
                })
                .catch((error: any) => console.error(error));
        }
    }, [categorizedRestrictions]);

    useEffect(() => {
        if (!plans) {
            paymentService.getPlans().then((res: any) => buildPlans(res)).catch((error: any) => console.error(error));
        }
    }, [paymentService, plans]);

    useEffect(() => {
        if (!structureTables && categorizedRestrictions && categorizedRestrictions.length >= 0
            && plans && plans.length >= 0) {
            buildTableStructures();
        }
    }, [structureTables, categorizedRestrictions, plans]);

    useEffect(() => {
        if (paginate.actual) {
            buildTableStructures();
        }
    }, [paginate])

    useEffect(() => {
        if (structureTables && structureTables.length) {
            const headers = structureTables[0].headers;
            const save = {
                key: 'save-button', text: 'Save', action: editHeaderId ? updatePlan : saveNewPlan, className: 'allowed'
            };
            const cancel = {
                key: 'cancel-button', text: 'Cancel', action: regenerateVariables, className: 'allowed'
            };
            const saveButton = generateWhiteButton(save);
            const cancelButton = generateWhiteButton(cancel);
            const boxButtons = (<Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {saveButton}
                {cancelButton}
            </Box>);
            let data: any = {};
            structureTables[0].headers.forEach((header: any, index: number) => {
                if (index === 0) {
                    data = { ...data, ...{ [header.key]: '', visible: false } };
                } else {
                    let vissibleButtons = index === 1 ? true : false;
                    if (editHeaderId) {
                        vissibleButtons = header.key === editHeaderId ? true : false;
                    }
                    data = { ...data, ...{ [header.key]: { component: boxButtons, visible: vissibleButtons } } };
                }
            });
            const newTableButtonsStructure = { headers: headers, data: [data], key: 'save-button', visibleHeaders: false };
            setTableButtons([newTableButtonsStructure]);
        } else {
            setTableButtons(null)
        }
    }, [structureTables]);

    const getRestrictionsByItemsRef = () => {
        let restrictions: any = {};
        itemsRef?.forEach((element: any) => {
            if (element.key && element.currentRef && element.currentRef.current && element.currentRef.current.value
                && element.currentRef.current.value !== '') {
                restrictions = { ...restrictions, ...{ [element.key]: element.currentRef.current.value.trim() } };
            }
        });
        return restrictions;
    }

    const saveNewPlan = () => {
        const restrictions = getRestrictionsByItemsRef();
        const namePlan = restrictions['new-plan'] ?? 'New Plan';
        delete restrictions['new-plan'];
        const newPlan: Plan = {
            name: namePlan, restrictions: restrictions, priceMonthly: 0,
            priceYearly: 0, visibility: 0
        }
        paymentService.createPlan(newPlan)
            .then((response: any) => {
                if (response.data) {
                    setTimeout(() => {
                        regenerateVariables();
                        setOpenInfoModal(addPlanModal);
                    }, 100);
                } else {
                    setOpenInfoModal(failedPlanModal);
                }
            })
            .catch((error: any) => {
                setOpenInfoModal(failedPlanModal);
                console.log(error);
            });
    }

    const updatePlan = () => {
        const restrictions = getRestrictionsByItemsRef();
        const keysRestrictions = Object.keys(restrictions);
        let plan = plans?.find((plan: Plan) => plan.id === editHeaderId);
        plan.name = restrictions[editHeaderId] ?? plan.name;
        let newRestrictions = {};
        categorizedRestrictions?.forEach((catRest: any) => {
            catRest?.data?.forEach((data: any) => {
                const oldRestriction = keysRestrictions.find((keyRest: any) => keyRest === data.name);
                if (oldRestriction) {
                    newRestrictions = { ...newRestrictions, ...{ [data.key]: restrictions[oldRestriction] } };
                    if (data.key === 'file_format') {
                        const splittedFileFormat = restrictions[oldRestriction]?.split(',');
                        newRestrictions = { ...newRestrictions, ...{ [data.key]: splittedFileFormat } };
                    }
                }
            })
        });
        plan.restrictions = newRestrictions;
        paymentService.updatePlan(plan)
            .then(() => {
                paymentService.updatePlanRestrictions(plan)
                    .then(() => {
                        setTimeout(() => {
                            setOpenInfoModal(editPlanModal);
                            regenerateVariables();
                        }, 100);
                    })
                    .catch((error: any) => {
                        setOpenInfoModal(failedPlanModal);
                        console.log(error);
                    })
            })
            .catch((error: any) => {
                setOpenInfoModal(failedPlanModal);
                console.log(error);
            });
    }

    const regenerateVariables = () => {
        setItemsRef([]);
        setPaginate({ actual: 0, total: 0 });
        setTableButtons(null);
        setVisibleTableButtons(false);
        setDisableAddButton(false);
        setPlans(null);
        setEditHeaderId(null);
        setStructureTables(null);
        localStorage.setItem('editMode', 'false');
        setEditableHeaders(true);
    }

    const generateMembersMainSection = () => {
        const title = generateTitleTipography('Subscription limitations', { fontSize: '18px' }, uuidv4());
        const TitleDescriptionBox = generateBox({ width: '80%' }, [title]);
        const componentsBox = [TitleDescriptionBox];
        const buttonAction = () => {
            addNewPlanToTables();
        };
        const addButton: standardButtonType = {
            key: 'add-button', text: 'Add new plan', icon: AddIcon, action: buttonAction,
            className: disableAddButton ? 'disabled' : ''
        };
        addButton.className += ' allowed';
        const addMemberButton = generateWhiteButton(addButton, disableAddButton);
        const addMemberButtonBox = generateBox(null, [addMemberButton]);
        componentsBox.push(addMemberButtonBox);
        const boxComponentStyle = { display: 'flex', marginTop: '2em', alignItems: 'center', justifyContent: 'space-between' };
        const BoxComponent = generateBox(boxComponentStyle, componentsBox);
        return BoxComponent;
    }

    const addNewPlanToTables = () => {
        const newPlanKey = 'new-plan';
        if (structureTables && structureTables.length && structureTables[0] && structureTables[0].headers
            && structureTables[0].headers.length && structureTables[0].headers[0]
            && structureTables[0].headers[0].title) {
            let newItemsRef = itemsRef;
            let newStructureTables = structureTables.map((structure: any) => {
                structure.editHeaderId = newPlanKey;
                const newPlanHeader = generateRowEditable({ title: 'New Plan', key: newPlanKey }, structure.key, newPlanKey);
                structure.headers = addElementPositionArray(structure.headers, 1, newPlanHeader);
                structure.data = structure.data.map((data: any) => {
                    const catRestriction: any = categorizedRestrictions.find((e: any) => e.key === structure.key);
                    const catRestrictionFind = catRestriction.data.find((ct: any) => ct.name === data[catRestriction.key]);
                    const valueData = catRestrictionFind?.type;
                    let newItemRef: any = {
                        key: catRestrictionFind?.key,
                        currentRef: createRef()
                    };
                    newItemsRef.push(newItemRef);
                    return {
                        ...data, ...{
                            [newPlanKey]: {
                                component:
                                    <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <Box sx={{ m: '5px' }}>
                                            {chipComponent(valueData)}
                                        </Box>
                                        <Box sx={{ m: '5px' }}>
                                            {generateInput(newItemRef.currentRef, null, null, null, valueData)}
                                        </Box>
                                    </Box>
                            }
                        }
                    };
                });
                return structure;
            });
            newStructureTables = deleteElementPositionToHeaders(newStructureTables, 6);
            setItemsRef(newItemsRef);
            setEditableHeaders(false);
            setStructureTables(newStructureTables);
            setDisableAddButton(true);
            setVisibleTableButtons(true);
            localStorage.setItem('editMode', 'true');
        }
    }

    const deleteElementPositionToHeaders = (structures: any[], position: number) => {
        const newStructures = structures.map((structure: any) => {
            let sixHeaderKey = '';
            if (structure.headers.length >= 6) {
                structure.headers = structure.headers.filter((header: any, index: number) => {
                    if (index === position) {
                        sixHeaderKey = header.key;
                        return false;
                    } else {
                        return header;
                    }
                });
                structure.data = structure.data.map((data: any) => {
                    delete data[sixHeaderKey];
                    return data;
                });
            }
            return structure;
        });
        return newStructures;
    }

    const handlePagination = (page: number) => {
        const editMode = localStorage.getItem('editMode');
        if (editMode === 'false') {
            paymentService.getPlans(page).then((res: any) => buildPlans(res, page));
        }
    }

    const addElementPositionArray = (arrayElements: any[], position: number, element: any) => {
        const newArrayElements =
            [arrayElements.slice(0, position), element,
            arrayElements.slice(position, arrayElements.length)]
                .flat();
        return newArrayElements;
    }

    const chipComponent = (label: string) => {
        return (<Chip label={capitalizeFirstLetter(label)} style={{ color: '#FD0B0B' }} />);
    }

    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const generateStructureTables = () => {
        let newStructureTables: any[] = [];
        categorizedRestrictions.forEach((cat: any, index: number) => {
            const headers = generateHeaderTable(cat);
            const data = generateDataTable(cat);
            newStructureTables.push({ headers: headers, data: data, key: cat.key });
        });
        return newStructureTables;
    }

    const generateHeaderTable = (restriction: any) => {
        let headers = [
            { 'title': restriction.key, 'key': restriction.key }
        ];

        plans?.forEach((plan: Plan) => {
            if (plan.name) {
                headers.push({ 'title': plan.name, 'key': plan.id });
            }
        });
        return headers;
    }

    const generateDataTable = (catRestrictions: any) => {
        let data: any[] = [];
        if (catRestrictions && catRestrictions.key) {
            if (catRestrictions.data.length) {
                catRestrictions.data.forEach((restriction: any) => {
                    let plansRestrictionValues: any = findRestrictionsPlanForKey(restriction.key);
                    const element = { [catRestrictions.key]: restriction.name, ...{ ...plansRestrictionValues } };
                    data.push(element);
                });
            }
        }
        return data;
    }

    const findRestrictionsPlanForKey = (key: string) => {
        let plansRestrictionValues: any = {};
        plans?.forEach((plan: Plan) => {
            if (plan.restrictions && plan.restrictions.length && Array.isArray(plan.restrictions)) {
                const planRestriction = plan.restrictions.find((planRest: Restriction) => planRest.key === key);
                if (planRestriction && plan.id) {
                    plansRestrictionValues = { ...plansRestrictionValues, ...{ [plan.id]: planRestriction.defaultValue + '' } };
                }
            }
        });
        return plansRestrictionValues;
    }

    const generateTables = (structures: any[]) => {
        return structures?.map((structure: any, index: number) => {
            const emptyPlanRestrictions = buildEmptyPlanRestrictions(structure);
            return generateTable(emptyPlanRestrictions, index);
        });
    }

    const buildEmptyPlanRestrictions = (struct: any) => {
        struct.headers.forEach((header: any) => {
            if (header.key !== header.title) {
                struct.data.forEach((row: any) => {
                    if (row[header.key] === undefined) {
                        row[header.key] = '';
                    } else {
                        row[header.key] = formatValue(row[header.key]);
                    }
                });
            }
        });
        return struct;
    }

    const generateTable = (structure: any, index: number) => {
        const tableKey = 'table-' + structure.key + '-' + index;
        const dontPrintHeaders = index === 0 ? null : structure?.headers[0];
        return (<Box
            sx={{ display: 'flex', mt: '15px' }}
            key={`box-table-${structure?.key}`}>
            <CustomTable
                class='planManagement'
                dontPrintHeaders={dontPrintHeaders}
                visibleHeaders={structure?.visibleHeaders}
                headers={structure?.headers}
                data={structure?.data}
                keys={tableKey}
                editHeaders={editableHeaders}
                editHeaderId={structure?.editHeaderId}
                editHeaderAction={(headerKey: string) => {
                    setItemsRef([]);
                    setEditHeaderId(headerKey);
                    enableEditionByKey(headerKey);
                }}
            />
        </Box>);
    }

    const enableEditionByKey = (key: string) => {
        localStorage.setItem('editMode', 'true');
        const newStructureTables = structureTables?.map((structure: any) => {
            structure?.headers?.map((header: any) => {
                const newHeader = header.key === key ? generateRowEditable(header, structure.key, key) : header;
                return newHeader;
            });
            structure?.data?.map((data: any) => {
                const newData = generateRowEditable(data, structure.key, key);
                return newData;
            });
            structure.editHeaderId = key;
            return structure;
        });
        setStructureTables(newStructureTables);
        setDisableAddButton(true);
        setVisibleTableButtons(true);
    }

    const generateRowEditable = (data: any, parentKey: string, key: string) => {
        let valueKey = data[key] ?? data?.title;
        if (valueKey) {
            switch ((valueKey + '').toLowerCase()) {
                case 'unlimited':
                    valueKey = '-1';
                    break;
                case 'yes':
                    valueKey = 'true';
                    break;
                case 'no':
                    valueKey = 'false';
                    break;
            }
        }
        let newItemRef: any = {
            key: data.title ? key : data[parentKey],
            currentRef: createRef()
        };
        let newItemsRef = itemsRef;
        newItemsRef.push(newItemRef);
        setItemsRef(newItemsRef);
        data[key] = {
            component:
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    {generateInput(newItemRef.currentRef, null, null, null, null, valueKey)}
                </Box>
        }
        return data;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {openInfoModal && generateModal(openInfoModal)}
            {
                openWarningModal
                    ? <WarningModal opener={[openWarningModal, setOpenWarningModal]} />
                    : null
            }
            <Box sx={{ display: 'flex' }}>
                <TitleTypography
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '40px' }} >
                    {props.title}
                </TitleTypography>
            </Box>
            <Box>
                {generateMembersMainSection()}
                {structureTables && generateTables(structureTables)}
                {visibleTableButtons && tableButtons && generateTables(tableButtons)}
            </Box >
            <Box sx={{ marginTop: '1.5em', alignSelf: 'flex-end' }}>
                <Pagination onChange={(event: React.ChangeEvent<unknown>, page: number) => handlePagination(page)} className='pagination' count={paginate.total} variant='outlined' shape='rounded' />
            </Box>
        </Box >
    );
}

PlanManagement.propTypes = {
    title: PropTypes.string.isRequired,
}

export default PlanManagement;