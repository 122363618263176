import { Restriction } from './../models/Restriction';
import { ACCOUNT_MEMBERS, RESTRICTION_FEATURES } from '../components/commons/constants';
import { AuthenticationClient } from "../client/AuthenticationClient";
import { Account } from "../models/Account";
import { Subscription } from "../models/Subscription";
import { User } from "../models/User";
import { PaymentService } from "./PaymentService";
import { Cookie } from '../utils/cookie';

const STATUS = ['Active', 'Pending', 'Innactive'];

type categoryRestriction = {
    key: string,
    data: any[]
}

export class AuthenticationService {

    private authenticationClient: AuthenticationClient;
    private paymentService: PaymentService;

    private _authToken: string;
    private _refreshToken: string;
    private authTokenExpiration: Date;

    private user: User | null;
    private account: Account | null | undefined;
    private subscription: Subscription | null | undefined;

    private restrictions: any[] | undefined;
    private categorizedRestrictions: categoryRestriction[] = [];
    private isSysAdmin = false;
    modal = () => { }

    static instance: AuthenticationService;

    public static getInstance(): AuthenticationService {
        if (!AuthenticationService.instance) {
            AuthenticationService.instance = new AuthenticationService();
        }
        return AuthenticationService.instance;
    }

    constructor() {
        this.authenticationClient = AuthenticationClient.getInstance();
        this._authToken = Cookie.get('token') || '';
        this._refreshToken = '';
        this.user = null;
        this.authTokenExpiration = new Date();
        this.paymentService = PaymentService.getInstance();
        if (this._authToken) {
            this.authenticateByToken(this._authToken);
        }
    }

    async authenticateByEmailAndPassword(email: string, password: string) {
        const loginResponse = await this.authenticationClient.login(email, password);
        this._authToken = '';
        this.user = null;
        this.authTokenExpiration = new Date();
        if (loginResponse.status && loginResponse.status === 200 && loginResponse.data && loginResponse.data.token) {
            this._authToken = loginResponse.data.token;
            Cookie.set('token', loginResponse.data.token);
            const newUser = this.createUser(loginResponse.data);
            this.setUser(newUser);
            if (newUser?.account) {
                this.buildAccount();
                await this.getActiveSubscription();
            }
            this.authTokenExpiration.setDate(this.authTokenExpiration.getDate() + 1);
            console.log('User authorized');
        } else {
            throw Error('Not authorized');
        }
    }

    async authenticateByToken(token: string): Promise<any> {
        const loginResponse = await this.authenticationClient.getProfile(token);
        this._authToken = '';
        this.user = null;
        this.authTokenExpiration = new Date();
        if (loginResponse.status && loginResponse.status === 200 && loginResponse.data && token) {
            this._authToken = token;
            Cookie.set('token', token);
            const newUser = this.createUser(loginResponse.data, token);
            this.setUser(newUser);
            if (newUser?.account) {
                this.buildAccount();
                await this.getActiveSubscription();
            }
            this.authTokenExpiration.setDate(this.authTokenExpiration.getDate() + 1);
            console.log('User authorized');
            return 'User authorized';
        } else {
            return Error('Not authorized');
        }
    }

    private async buildAccount() {
        if (!this.user?.account) {
            return Error('Not authorized');
        }
        const newAccount = this.createAccount(this.user?.account);
        this.setAccount(newAccount);
        return newAccount;
    }

    private createUser(data: any, token?: string): User | null {
        const user = new User(
            data?.id || data?.user?.id,
            data?.firstName || data?.user?.firstName,
            data?.lastName || data?.user?.lastName,
            data?.email || data?.user?.email,
            data?.scope || data?.user?.scope,
            new Date(data?.createdDate) || new Date(data?.user?.createdDate),
            data?.slackId || data?.user?.slackId,
            data?.account || data?.user?.account,
            token || null);
        return user;
    }

    private createAccount(data: any): Account | null {
        const account = new Account(
            data.id,
            data.name,
            data.ownerUserId,
            data.companyName,
            data.companyWebsite,
            data.companyIndustry,
            data.companySubsidiaries,
            data.restrictions,
        );
        return account;
    }

    setUser(user: User | null) {
        if (user?.scope) {
            this.isSysAdmin = user.scope.includes("admin");
        }
        this.user = user;
    }

    setAccount(account: Account | null) {
        this.account = account;
    }

    groupRestrictionsByKey(restrictions: Restriction[]): Restriction[] {
        const allSysRestrictions = restrictions;
        const restrictionsWithHeader = allSysRestrictions.filter((sysRestriction: any) => {
            const foundRestriction = allSysRestrictions.find((accRestriction: any) => {
                return (sysRestriction.key === accRestriction.key);
            });
            if (foundRestriction) {
                sysRestriction.header = RESTRICTION_FEATURES[foundRestriction.key as keyof typeof RESTRICTION_FEATURES];
                return true;
            }
            return false;
        });
        return restrictionsWithHeader;
    }

    buildcategorizedRestrictions(restrictionArray: Restriction[]) {
        let categorizeRestrictions: any[] = [];
        restrictionArray.forEach((restr: Restriction) => {
            const categorized = categorizeRestrictions
                .find((catRestriction: categoryRestriction) => restr.header === catRestriction.key);
            if (categorized) {
                const restE = categorized.data.find((rH: any) => rH.key === restr.key);
                if (!restE) {
                    categorized.data.push(restr);
                }
            } else if (restr.header) {
                categorizeRestrictions.push({ key: restr.header, data: [restr] });
            }
        });
        return categorizeRestrictions;
    }

    setRestrictions(restrictions: Restriction[]) {
        this.categorizedRestrictions = this.buildcategorizedRestrictions(restrictions);
        this.restrictions = restrictions;
    }

    setSubscription(subscription: Subscription | null) {
        this.subscription = subscription;
    }

    async getSystemRestriction(): Promise<Restriction[]> {
        const restrictionsResponse = await this.authenticationClient.getRestrictions(this._authToken);
        if (restrictionsResponse.status === 200 && restrictionsResponse.data) {
            if (restrictionsResponse.data?.data?.length) {
                const restrictionsWithHeader = this.groupRestrictionsByKey(restrictionsResponse.data.data);
                const categorizedRestrictions = this.buildcategorizedRestrictions(restrictionsWithHeader);
                return categorizedRestrictions;
            }
        }
        return [];
    }

    get userInfo() {
        return this.user;
    }

    get userisAdmin() {
        return this.isSysAdmin;
    }

    get accountInfo() {
        return this.account;
    }

    get subscriptionInfo() {
        return this.subscription;
    }

    get restrictionsInfo() {
        return this.restrictions;
    }

    get categorizedRestrictionsInfo() {
        return this.categorizedRestrictions;
    }

    get subscriptionStatus() {
        if (typeof this.subscription?.status === 'number') {
            return STATUS[this.subscription.status] ?? 'Unexpected';
        }
    }

    get isOwner() {
        return this.user?.id === this.account?.ownerUserId;
    }

    async recoverPassword(email: string) {
        const recoverPasswordResponse = await this.authenticationClient.recoverPasswordByEmail(email);
        if (!recoverPasswordResponse || !recoverPasswordResponse.status || recoverPasswordResponse.status !== 204) {
            throw Error('User not exists');
        }
    }

    get authToken(): string {
        return this._authToken;
    }

    public isAuthenticated() {
        const now = new Date();
        if (this.user && this.authTokenExpiration.getTime() > now.getTime() && this._authToken) {
            return true;
        }
        return false;
    }

    async userExists(email: string) {
        const checkEmailIsValidResponse = await this.authenticationClient.checkEmailIsValid(email);
        if (!checkEmailIsValidResponse || !checkEmailIsValidResponse.status || checkEmailIsValidResponse.status !== 204) {
            throw Error('User not exists');
        }
    }

    async userUpdate({ firstName, lastName }: User) {
        if (!this.user?.id) {
            throw Error('Unauthorized');
        }
        this.authenticationClient.updateUser(this._authToken, { firstName, lastName });
    }

    async accountUpdate({ name, companyName, companyWebsite, companySubsidiaries, companyIndustry }: Account) {
        if (!this.user?.account) {
            throw Error('Unauthorized');
        }
        this.authenticationClient.updateAccount(this._authToken, { name, companyName, companyWebsite, companySubsidiaries, companyIndustry });
    }

    public logout() {
        this._authToken = '';
        this.setUser(null);
        this.navigateToWebsite();
        Cookie.remove('token');
    }

    public navigateToWebsite() {
        const baseUrl: string = String(process.env.REACT_APP_AIZWEI_WEB_BASE_URL) +
            String(process.env.REACT_APP_AIZWEI_WEB_PREFIX_URI);
        window.location.href = baseUrl;
    }

    // #TODO: CHANGE RESTRICTIONS
    private async buildRestrictions(accountRestrictions: Restriction[]) {
        const response = await this.authenticationClient.getRestrictions(this._authToken);
        const maxUserPlan = {
            data: {
                description: 'Nr of connected users per account',
                key: ACCOUNT_MEMBERS,
                defaultValue: 0,
                header: RESTRICTION_FEATURES[ACCOUNT_MEMBERS]
            },
            found: false
        };
        if (response.status === 200 && response.data) {
            const allSysRestrictions = (response?.data?.data || []);
            const restrictionsWithHeader = allSysRestrictions.filter((sysRestriction: any) => {
                if (sysRestriction.key === ACCOUNT_MEMBERS) {
                    maxUserPlan.data = { header: maxUserPlan.data.header, ...sysRestriction }
                }
                const foundRestriction = accountRestrictions.find((accRestriction: any) => {
                    if (accRestriction.key === ACCOUNT_MEMBERS) {
                        maxUserPlan.found = true;
                    }
                    return (sysRestriction.key === accRestriction.key);
                });
                if (foundRestriction && !this.isSysAdmin) {
                    sysRestriction.header = RESTRICTION_FEATURES[foundRestriction.key as keyof typeof RESTRICTION_FEATURES];
                    sysRestriction.defaultValue = foundRestriction.defaultValue;
                    return true;
                } else if (this.isSysAdmin) {
                    sysRestriction.header = RESTRICTION_FEATURES[sysRestriction.key as keyof typeof RESTRICTION_FEATURES];
                    sysRestriction.defaultValue = sysRestriction.defaultValue;
                    return true;
                }
                return false;
            });

            // If the account restrictions came without the account members restriction, we use the actual users number
            if (!maxUserPlan.found) {
                const accountUsers = await this.authenticationClient.getAccountsWithUsers(this._authToken);
                if (accountUsers.status === 200 && accountUsers?.data?.data && accountUsers.data.collectionSize) {
                    maxUserPlan.data = { ...maxUserPlan.data, defaultValue: accountUsers.data.collectionSize };
                    restrictionsWithHeader.unshift(maxUserPlan.data);
                }
            }
            this.setRestrictions(restrictionsWithHeader);
        } else {
            throw Error('Unabele to find a restrictions');
        }
    }

    async getActiveSubscription() {
        const subscription = this.paymentService?.subscriptionInfo;
        if (!subscription && this.account) {
            const newSubscription = await this.paymentService.getActiveSubscription(this._authToken);
            if (newSubscription) {
                this.setSubscription(newSubscription);
                const restrictions = newSubscription?.asset?.restrictions;
                if (restrictions && Array.isArray(restrictions)) {
                    this.buildRestrictions(restrictions);
                }
            } else {
                console.error('Unable to find an active subscription');
            }
        }
    }

    async refreshToken() {
        if (this._refreshToken && this.user && this.user.id) {
            const response = await this.authenticationClient.refreshToken(this._refreshToken, this.user.id);
            if (response.status && response.status === 200 && response.data) {
                this._authToken = response.data.token;
                this._refreshToken = response.data.refreshToken;
                const newUser = this.createUser(response.data);
                this.setUser(newUser);
            }
        }
    }

    getRefreshToken() {
        return this._refreshToken;
    }

    setRefreshToken(refreshToken: any) {
        this._refreshToken = refreshToken;
    }

    getTokenExpiration() {
        return this.authTokenExpiration;
    }

    async changePassword(oldPass: any, newPass: any, confirmPass: any) {
        const loginResponse = await this.authenticationClient.changePassword(this._authToken, oldPass, newPass, confirmPass);
        if (loginResponse.status && loginResponse.status === 204) {
            return 'Password changed';
        } else {
            return Error('Password change failed');
        }
    }

    async getAccountsWithUsers(page: number = 1, size: number = 10) {
        const loginResponse = await this.authenticationClient.getAccountsWithUsers(this._authToken, page, size);
        if (loginResponse.status && loginResponse.status === 200 && loginResponse.data) {
            return loginResponse.data;
        } else {
            return Error('Error obtaining account\'s users');
        }
    }

    async addNewUserAccount(firstName: string, lastName: string, email: string) {
        const response = await this.authenticationClient.addNewUserAccount(this._authToken, firstName, lastName, email);
        if (response.status && response.status === 204) {
            return true;
        } else {
            return Error('Error add new user');
        }
    }

    async removeUserAccount(userId: string) {
        const response = await this.authenticationClient.removeUserAccount(this._authToken, userId);
        if (response.status && response.status === 204) {
            return true;
        } else {
            return Error('Error delete user');
        }
    }

    async getAccounts(page: number = 1, size: number = 20) {
        const accountsResponse = await this.authenticationClient.getAccounts(this._authToken, page, size);
        if (accountsResponse.status && accountsResponse.status === 200 && accountsResponse.data) {
            return accountsResponse.data;
        } else {
            return Error('Error obtaining accounts');
        }
    }

    async getUserById(userId: string) {
        const accountsResponse = await this.authenticationClient.getUserById(this._authToken, userId);
        if (accountsResponse.status && accountsResponse.status === 200 && accountsResponse.data) {
            return accountsResponse.data;
        } else {
            return Error('Error obtaining user information');
        }
    }

    async recoverPasswordToken(email: string) {
        const recoverPasswordResponse = await this.authenticationClient.recoverPasswordToken(email);
        if (!recoverPasswordResponse || !recoverPasswordResponse.status || recoverPasswordResponse.status !== 204) {
            throw Error('User not exists')
        }
        return recoverPasswordResponse;
    }
}