import * as React from 'react';
import './tab-panel.scss'
import { Box } from '@mui/material';

interface TabPanelProps {
    index: number;
    value: number;
    componentPanel: React.ReactNode;
}

const TabPanel = (props: TabPanelProps) => {
    const { value, index, componentPanel, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            className='tabpanel'
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {componentPanel}
                </Box>
            )}
        </div>
    );
}

export default TabPanel;