interface ComposedName {
    title: string;
    description: string;
}

export class AccountMemberRow {
    id: string;
    name: string | ComposedName;
    role?: string;
    models?: number;
    components?: AccountMemberRow[] | null;
    planName?: string;
    user?: string | ComposedName;

    constructor(id: string, name: string | ComposedName, role?: string, models?: number, components: AccountMemberRow[] | null = null, planName?: string, user?: string | ComposedName,) {
        this.id = id;
        this.name = name;
        this.role = role;
        this.models = models;
        this.components = components;
        this.planName = planName;
        this.user = user;
    }
}