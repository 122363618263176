import axios from 'axios';

export class AuthenticationClient {

    baseUrl: string;

    static instance: AuthenticationClient;

    public static getInstance(): AuthenticationClient {
        if (!AuthenticationClient.instance) {
            AuthenticationClient.instance = new AuthenticationClient();
        }

        return AuthenticationClient.instance;
    }

    constructor() {
        const baseUrl: string = String(process.env.REACT_APP_AUTHENTICATION_API_BASE_URL) +
            String(process.env.REACT_APP_AUTHENTICATION_API_PREFIX_URI) + '/auth';
        this.baseUrl = baseUrl;
    }

    async login(email: string, password: string) {
        const request = { email: email, password: password };
        const response = await axios.post(this.baseUrl + '/login', request);
        return response;
    }

    async recoverPasswordByEmail(email: string) {
        const request = { email: email };
        const response = await axios.post(this.baseUrl + '/soft-recover', request);
        return response;
    }

    async checkEmailIsValid(email: string) {
        const body = { email: email };
        const response = await axios.post(this.baseUrl + '/email', body);
        return response;
    }

    async getProfile(token: string) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + '/users/profile', { headers });
        return response;
    }

    async getAccount(token: string, accountId: string) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + '/accounts/' + accountId, { headers });
        return response;
    }

    async getRestrictions(token: string) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + '/restrictions', { headers });
        return response;
    }

    async updateUser(token: string, userData: any) {
        const headers = this.generateHeaders(token);
        const response = await axios.post(this.baseUrl + '/users/update', userData, { headers });
        return response;
    }

    async updateAccount(token: string, accountData: any) {
        const headers = this.generateHeaders(token);
        const response = await axios.post(this.baseUrl + '/accounts/update/accountInfo/', accountData, { headers });
        return response;
    }

    async refreshToken(refreshToken: string, userId: string) {
        const request = { refreshToken: refreshToken, userId: userId };
        const response = await axios.post(this.baseUrl + '/refreshToken', request);
        return response;
    }

    async changePassword(token: string, oldPassword: string, newPassword: string, confirmPassword: string) {
        const headers = this.generateHeaders(token);
        const request = { oldPassword: oldPassword, newPassword: newPassword, confirmPassword: confirmPassword };
        const response = await axios.post(this.baseUrl + '/users/password', request, { headers });
        return response;
    }

    generateHeaders(token: string) {
        return {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        };
    }

    async getAccountsWithUsers(token: string, page: number = 1, size: number = 10) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + `/accounts/users?page=${page}&size=${size}`, { headers });
        return response;
    }

    async addNewUserAccount(token: string, firstName: string, lastName: string, email: string) {
        const headers = this.generateHeaders(token);
        const request = { firstName: firstName, lastName: lastName, email: email };
        const response = await axios.post(this.baseUrl + '/accounts/users/add', request, { headers });
        return response;
    }

    async removeUserAccount(token: string, userId: string) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + '/accounts/users/remove/' + userId, { headers });
        return response;
    }

    async getAccounts(token: string, page: number, size: number) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + `/accounts?page=${page}&size=${size}`, { headers });
        return response;
    }

    async getUserById(token: string, userId: string) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + '/users/' + userId, { headers });
        return response;
    }

    async recoverPasswordToken(email: string) {
        const response = await axios.get(this.baseUrl + '/users/recoverPassword/' + email + '?mode=0');
        return response;
    }
}