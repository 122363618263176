export class AccountMemberHeader {
    key: string;
    title: string;
    icon: any;

    constructor(key: string, title: string, icon: any = null) {
        this.key = key;
        this.title = title;
        this.icon = icon;
    }
}