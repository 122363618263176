import { Cookie } from "../utils/cookie";
import { PaymentClient } from "../client/PaymentClient";
import { Plan } from "../models/Plan";
import { Subscription } from "../models/Subscription";

export class PaymentService {

    private paymentClient: PaymentClient;
    static instance: PaymentService;
    private _authToken: string;
    private subscription: Subscription | null | undefined;

    public static getInstance(): PaymentService {
        if (!PaymentService.instance) {
            PaymentService.instance = new PaymentService();
        }

        return PaymentService.instance;
    }

    constructor() {
        this._authToken = Cookie.get('token') || '';
        this.paymentClient = PaymentClient.getInstance();
    }

    private get token() {
        if (!this._authToken) {
            this._authToken = Cookie.get('token') || '';
        }
        return this._authToken;
    }

    private createSubscription(data: any): Subscription | null {
        const subscription = new Subscription(
            data.id,
            data.asset,
            data.accountId,
            data.status,
            data.expirationDate,
            data.creationDate
        );
        return subscription;
    }

    private async buildSubscription(subscription: Subscription) {
        if (!subscription) {
            return Error('Not authorized');
        }
        const newSubscription = this.createSubscription(subscription);
        this.setSubscription(newSubscription);
        return newSubscription;
    }

    setSubscription(subscription: Subscription | null) {
        this.subscription = subscription;
    }

    get subscriptionInfo() {
        return this.subscription;
    }

    async getActiveSubscription(token: string = this._authToken) {
        try {
            const subscription = await this.paymentClient.getActiveSubscription(token);
            if (subscription && subscription.status && subscription.status === 200 && subscription.data) {
                this.buildSubscription(subscription.data);
                return this.subscription;
            }
        } catch (error: any) {
            console.error(error?.response?.data?.message);
        }
        return false;
    }

    async getPlans(page: number = 1, size: number = 6): Promise<Plan[] | null> {
        const plans = await this.paymentClient.getPlans(this.token, page, size);
        if (plans && plans.status && plans.status === 200 && plans.data) {
            if (plans.data && plans.data.data) {
                return plans.data;
            } else {
                return [];
            }
        }
        return null;
    }

    async updateSubcriptionPlan() {
        if (!this.subscription?.id) {
            throw Error('Unauthorized');
        }
        return this.paymentClient.updateSubcriptionPlan(this._authToken, this.subscription?.id);
    }

    async createPlan(newPlan: Plan) {
        return this.paymentClient.createPlan(this._authToken, newPlan);
    }

    async updatePlan(updatePlan: Plan) {
        if (!updatePlan?.id) {
            throw Error('Unauthorized');
        }
        return this.paymentClient.updatePlan(this._authToken, updatePlan);
    }

    async updatePlanRestrictions(updatePlan: Plan) {
        if (!updatePlan?.id) {
            throw Error('Unauthorized');
        }
        return this.paymentClient.updatePlanRestrictions(this._authToken, updatePlan);
    }

    async getSubscriptionsAccountActive(accountId: string): Promise<Subscription[] | null> {
        const subcription = await this.paymentClient.getSubscriptionsAccountActive(this.token, accountId);
        if (subcription && subcription.status && subcription.status === 200 && subcription.data) {
            if (subcription.data && subcription.data.asset) {
                return subcription.data;
            }
        }
        return null;
    }
}