import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import { Position } from '../../../models/Notification';

const CustomNotification = (props: any) => {
    const notificationPosition: Position = props.position ?? { vertical: 'top', horizontal: 'center' };
    return (
        <Snackbar anchorOrigin={notificationPosition} open={props.open} autoHideDuration={props.duration ?? 6000} onClose={props.onClose}>
            <Alert onClose={props.onClose} severity="info" sx={{ width: '100%' }}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}

CustomNotification.propTypes = {
    position: PropTypes.object,
    onClose: PropTypes.func,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    duration: PropTypes.number,
}

CustomNotification.defaultProps = {
    onClose: () => { }
}

export default CustomNotification;