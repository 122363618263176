export interface AccountProfile {
    id: string,
    name: string,
    ownerUserId: string,
    restrictions: any[];
    companyName: string;
    companyWebsite: string;
    companyIndustry: string;
    companySubsidiaries: string;
}

export class User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    scope: string[];
    createdDate: Date;
    slackId: string | null;
    account: AccountProfile | null | string;
    token: string | null;

    constructor(id: string, firstName: string, lastName: string, email: string, scope: string[], createdDate: Date, slackId: string | null = null, account: AccountProfile | null = null, token: string | null = null) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.scope = scope;
        this.createdDate = createdDate;
        this.slackId = slackId;
        this.account = account;
        this.token = token;
    }
}