import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#F9FAFB',
        color: '#101828',
        background: '#F9FAFB',
        borderBottom: '1px solid #EAECF0',
        fontSize: 12,
        '&:last-child': {
            width: '10%',
        }
    },
    [`&.${tableCellClasses.body}`]: {
        color: '#667085',
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));