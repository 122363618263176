import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './custom-modal.scss';
import { generateWhiteButton, generateTitleTipography } from '../../pages/utils';

const classes = {
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        width: '498px',
        userSelect: 'none',
        background: '#FFFFFF',
        borderRadius: '10px',
    }
};

const WarningModal = (props: any) => {
    const [open, setOpen] = props.opener;
    const warning = {
        title: 'Warning!',
        message: 'You have to save the changes first.',
        buttons: [{
            text: 'OK',
            className: 'allowed',
            style: { width: '100%', marginTop: '2em' },
            action: () => {
                setOpen(false);
            }
        }]
    }
    return (
        <Modal
            className='centerModal'
            disablePortal={true}
            open={open}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            disableAutoFocus
        >
            <Box sx={classes.box}>
                {generateTitleTipography(warning.title, { fontSize: '20px', fontFamily: 'ft-system-bold' })}
                {generateTitleTipography(warning.message, { fontSize: '16px', fontFamily: 'ft-system-regular' })}
                {warning.buttons.map(generateWhiteButton)}
            </Box>
        </Modal >
    );
}

export default WarningModal;