import axios from 'axios';
import { Plan } from '../models/Plan';

export class PaymentClient {

    baseUrl: string;

    static instance: PaymentClient;

    public static getInstance(): PaymentClient {
        if (!PaymentClient.instance) {
            PaymentClient.instance = new PaymentClient();
        }

        return PaymentClient.instance;
    }

    constructor() {
        const baseUrl: string = String(process.env.REACT_APP_PAYMENT_API_BASE_URL) +
            String(process.env.REACT_APP_PAYMENT_API_PREFIX_URI) + '/payment';
        this.baseUrl = baseUrl;
    }

    async getActiveSubscription(token: string) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + '/account/subscriptions/active', { headers });
        return response;
    }

    async getSubscription(token: string) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + '/account/subscriptions', { headers });
        return response;
    }

    async getPlans(token: string, page: number, size: number) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + `/plans?page=${page}&size=${size}&sortBy=createdDate&order=desc`, { headers });
        return response;
    }

    generateHeaders(token: string) {
        return {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        };
    }

    async updateSubcriptionPlan(token: string, subscriptionId: string) {
        const headers = this.generateHeaders(token);
        const request = { subscriptionPlanId: subscriptionId }
        const response = await axios.post(this.baseUrl + '/account/upgradeSubscriptionPlan', request, { headers });
        return response;
    }

    async createPlan(token: string, newPlan: Plan) {
        const headers = this.generateHeaders(token);
        const response = await axios.post(this.baseUrl + '/plans', newPlan, { headers });
        return response;
    }

    async updatePlan(token: string, updatePlan: Plan) {
        const headers = this.generateHeaders(token);
        const request = {
            visibility: updatePlan.visibility, priceYearly: updatePlan.priceYearly,
            priceMonthly: updatePlan.priceMonthly, name: updatePlan.name
        }
        const response = await axios.post(this.baseUrl + '/plans/' + updatePlan.id, request, { headers });
        return response;
    }

    async updatePlanRestrictions(token: string, updatePlan: Plan) {
        const headers = this.generateHeaders(token);
        const request = { ...updatePlan.restrictions };
        const response = await axios.post(this.baseUrl + '/plans/' + updatePlan.id + '/restrictions', request, { headers });
        return response;
    }

    async getSubscriptionsAccountActive(token: string, accountId: string) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + `/subscriptions/account/` + accountId + '/active', { headers });
        return response;
    }

}