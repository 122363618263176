import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { TitleTypography } from '../components/styled-components/title-typography';
import { CustomButton } from '../components/styled-components/custom-button';
import { v4 as uuidv4 } from 'uuid';
import Input from '../components/input/Input';
import { AuthenticationService } from '../services/AuthenticationService';
import PasswordModal from '../components/password-modal/password-modal';
import CustomForgotPassword from '../components/custom-forgot-password/custom-forgot-password';
import CustomLinkSent from '../components/custom-link-sent/custom-link-sent';

const AccountPage = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const user = authenticationService.userInfo;
    const account = authenticationService.accountInfo;
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
    const [openLinkSentModal, setOpenLinkSentModal] = useState(false);

    const isOwner = authenticationService.isOwner;

    const printUserData = () => {
        if (user?.firstName) {
            firstName.current.value = user?.firstName;
        }
        if (user?.lastName) {
            lastName.current.value = user?.lastName;
        }
        if (user?.email) {
            email.current.value = user?.email;
        }
    };

    const printAccountData = () => {
        if (account) {
            if (account?.companyName) {
                companyName.current.value = account?.companyName;
            }
            if (account?.companyWebsite) {
                website.current.value = account?.companyWebsite;
            }
            if (account?.companySubsidiaries) {
                subsidiaries.current.value = account?.companySubsidiaries;
            }
            if (account?.companyIndustry) {
                industry.current.value = account?.companyIndustry;
            }
        }
    };

    useEffect(() => {
        printUserData();
        printAccountData();
    }, []);

    const firstName = useRef<any>(null);
    const lastName = useRef<any>(null);
    const email = useRef<any>(null);
    const password = useRef<any>(null);

    const companyName = useRef<any>(null);
    const website = useRef<any>(null);
    const subsidiaries = useRef<any>(null);
    const industry = useRef<any>(null);

    const SECTIONS = {
        USER: 'user',
        COMPANY: 'company'
    };

    const attributeChangeValue = (object: any, attribute: string, value: any) => {
        if (value && object && attribute) {
            object[attribute] = value;
        }
    };

    const updateSectionData = (sectionName: any) => {
        switch (sectionName) {
            case SECTIONS.USER:
                if (user) {
                    attributeChangeValue(user, 'firstName', firstName.current.value);
                    attributeChangeValue(user, 'lastName', lastName.current.value);
                }
                break;
            case SECTIONS.COMPANY:
                if (account) {
                    attributeChangeValue(account, 'companyName', companyName.current.value);
                    attributeChangeValue(account, 'companyWebsite', website.current.value);
                    attributeChangeValue(account, 'companySubsidiaries', subsidiaries.current.value);
                    attributeChangeValue(account, 'companyIndustry', industry.current.value);
                }
                break;
        }
    };

    const ACCOUNT_BUTTONS = [
        {
            key: uuidv4(), text: 'Save', style: { margin: '10px' }, onClick: async (sectionName: any) => {
                updateSectionData(sectionName);
                if (sectionName === SECTIONS.USER && user) {
                    await authenticationService.userUpdate(user);
                } else if (sectionName === SECTIONS.COMPANY && account) {
                    await authenticationService.accountUpdate(account);
                }
            }
        }
    ];

    const generateAccountMainBox = () => { //1
        const sectionMainBoxElements = [];
        const tinyTextStyle = {
            display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
            fontSize: '14px', color: '#667085', fontFamily: 'ft-system-regular'
        };
        const regularTextStyle = {
            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '18px'
        };
        const componentsBoxStyle = { mt: 3, display: 'flex', flexDirection: 'column', width: '100%' };
        const userInfoBox: any = generateUserInfoMainBox(tinyTextStyle, regularTextStyle, componentsBoxStyle);
        sectionMainBoxElements.push(userInfoBox);
        if (account) {
            const companyProfileMainBox: any = generateCompanyProfileMainBox(tinyTextStyle, regularTextStyle, componentsBoxStyle);
            sectionMainBoxElements.push(companyProfileMainBox);
        }
        const UserMainBox: any = generateBox(componentsBoxStyle, sectionMainBoxElements);
        return UserMainBox;
    };

    //USER GENERATOR
    const generateUserInfoMainBox = (tinyTextStyle: any, regularTextStyle: any, componentsBoxStyle: any) => {
        const title = generateUserTitleBox(tinyTextStyle, regularTextStyle);
        const components = generateFullNameEmailUserBox(componentsBoxStyle, tinyTextStyle);
        const buttons = generateButtons(ACCOUNT_BUTTONS, SECTIONS.USER);
        const box = generateBox(componentsBoxStyle, [title, components, buttons]);
        return box;
    };

    //USER TITLE
    const generateUserTitleBox = (tinyTextStyle: any, regularTextStyle: any) => { //2
        const UserInfoTitle: any = generateTitleTypography(regularTextStyle, 'User info');
        const UserDescriptionTitle: any = generateTitleTypography(tinyTextStyle, 'Update the user info here');
        const UserTitleBox: any = generateBox({ mt: 0, display: 'flex', flexDirection: 'column' },
            [UserInfoTitle, UserDescriptionTitle]);
        return UserTitleBox;
    };

    //USER BOX
    const generateFullNameEmailUserBox = (componentBoxStyle: any, tinyTextStyle: any) => { //3
        const fullNameElements = [
            { ref: firstName, text: 'First name', type: 'text' },
            { ref: lastName, text: 'Last name', type: 'text' }
        ];
        const emailPassElements = [
            { ref: email, text: 'Email', type: 'text', disabled: true },
            {
                ref: password, type: 'component',
                component: {
                    type: 'button',
                    component: {
                        key: uuidv4(),
                        text: 'Change password',
                        style: { marginRight: '40px' },
                        action: () => { setOpenPasswordModal(true); }
                    }
                }
            }
        ];
        const firstLastNameBox: any = generateComponentInfoBox(componentBoxStyle, tinyTextStyle, fullNameElements);
        let newComponentBoxStyle = { ...componentBoxStyle, ...{ justifyContent: 'flex-end !important' } };
        const emailPassBox: any = generateComponentInfoBox(newComponentBoxStyle, tinyTextStyle, emailPassElements);
        const UserMainBox: any = generateBox({ ...componentBoxStyle, mt: 0 }, [firstLastNameBox, emailPassBox]);
        return UserMainBox;
    };

    //BOX Data
    const generateComponentTypeBox = (componentBoxStyle: any, tinyTextStyle: any, element?: any) => {
        const title: any = generateTitleTypography(tinyTextStyle, element?.text);
        let newElement = null;
        switch (element.type) {
            case 'text':
            case 'password':
                newElement = generateInput({ mr: 5, color: '#101828' }, element?.ref, element?.text, element?.type, element?.disabled);
                break;
            case 'component':
                newElement = generateComponent(element.component);
                break;
        }
        const box: any = generateBox(componentBoxStyle, [title, newElement]);
        return box;
    };

    const generateComponent = (component: any) => {
        let newElement = <></>;
        if (component) {
            switch (component.type) {
                case 'button':
                    newElement = generateButton(component?.component, component?.component?.action);
                    break;
            }
        }
        return newElement;
    };

    //COMPANY GENERATOR ( TITLE + BOX )
    const generateCompanyProfileMainBox = (tinyTextStyle: any, regularTextStyle: any, componentsBoxStyle: any) => {
        const title = generateCompanyProfileTitleBox(tinyTextStyle, regularTextStyle);
        const components = generateCompanynBox(componentsBoxStyle, tinyTextStyle);
        const box = generateBox(componentsBoxStyle, [title, components]);
        return box;
    };

    //COMPANY TITLE
    const generateCompanyProfileTitleBox = (tinyTextStyle: any, regularTextStyle: any) => { //2
        const UserInfoTitle: any = generateTitleTypography(regularTextStyle, 'Company profile');
        const UserDescriptionTitle: any = generateTitleTypography(tinyTextStyle, 'Update your company information here.e');
        const UserMainBox: any = generateBox({ mt: 3, display: 'flex', flexDirection: 'column' },
            [UserInfoTitle, UserDescriptionTitle]);
        return UserMainBox;
    };

    //COMPANY BOX
    const generateCompanynBox = (componentBoxStyle: any, tinyTextStyle: any) => { //3
        const companyBoxElements = [];
        const fullNameElements = [
            { ref: companyName, text: 'Company name', type: 'text' },
            { ref: website, text: 'Website', type: 'text' }
        ];
        const emailPassElements = [
            { ref: subsidiaries, text: 'Subsidiaries', type: 'text' },
            { ref: industry, text: 'Industry', type: 'text' }
        ];
        const firstLastNameBox: any = generateComponentInfoBox(componentBoxStyle, tinyTextStyle, fullNameElements, !isOwner);
        const emailPassBox: any = generateComponentInfoBox(componentBoxStyle, tinyTextStyle, emailPassElements, !isOwner);
        companyBoxElements.push(firstLastNameBox, emailPassBox);
        if (isOwner) {
            const buttons = generateButtons(ACCOUNT_BUTTONS, SECTIONS.COMPANY);
            companyBoxElements.push(buttons);
        }
        const UserMainBox: any = generateBox({ ...componentBoxStyle, mt: 0 }, companyBoxElements);
        return UserMainBox;
    };

    //BOX DATA
    const generateComponentInfoBox = (componentBoxStyle: any, tinyTextStyle: any, elements: any[], disabled: boolean = false) => {//4
        let namesBox = elements.map((element: any) =>
            generateComponentTypeBox(componentBoxStyle, tinyTextStyle, element));

        const newStyle = { ...componentBoxStyle, flexDirection: 'row', mt: 0, width: '65%' };
        const box: any = generateBox(newStyle, namesBox);
        return box;
    };


    const generateBox = (style: any, BoxComponent: any) => {
        return (<Box sx={style}>
            {BoxComponent}
        </Box>);
    };

    const generateTitleTypography = (style: any, text: any) => {
        return (<TitleTypography style={style} key={uuidv4()} >
            {text}
        </TitleTypography>);
    };

    const generateButtons = (buttons: any[], section: string) => {
        return <div className='sectionButtons' key={uuidv4()}>
            {buttons.map((button: any) => {
                return generateButton(button, section);
            })}
        </div>;
    };

    const generateButton = (button: any, actionFunction?: any) => {
        return <CustomButton
            key={button?.key ?? uuidv4()}
            style={button?.style}
            onClick={() => {
                if (typeof actionFunction === 'string') {
                    button.onClick(actionFunction);
                } else {
                    actionFunction();
                }
            }}
            className='btnWhite'
        >
            {button?.text}
        </CustomButton>;
    };

    const generateInput = (style: any, refData: any, placeholder: any, type?: any, disabled: boolean = false) => {
        return (<Input
            ref={refData}
            value={refData?.current?.value}
            key={refData?.current?.key}
            disabled={disabled}
            type={type ?? 'text'}
            style={style}
            placeholder={placeholder}
        />);
    };

    const changeModals = (modalType: string) => {
        setOpenPasswordModal(false);
        setOpenForgotPasswordModal(false);
        setOpenLinkSentModal(false);
        if (modalType === 'forgot-password') {
            setOpenForgotPasswordModal(true);
        } else if (modalType === 'link-sent') {
            setOpenLinkSentModal(true);
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex' }}>
                <TitleTypography
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '40px' }} >
                    {props.title}
                </TitleTypography>
            </Box>
            {generateAccountMainBox()}
            {openPasswordModal &&
                <PasswordModal open={openPasswordModal} changeOpen={setOpenPasswordModal} onChange={changeModals} />}
            {openForgotPasswordModal &&
                <CustomForgotPassword open={openForgotPasswordModal} changeOpen={setOpenForgotPasswordModal} onChange={changeModals} />}
            {openLinkSentModal &&
                <CustomLinkSent open={openLinkSentModal} changeOpen={setOpenLinkSentModal} onChange={changeModals} />}
        </Box>
    );
};

export default AccountPage;