import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { CustomButton } from '../styled-components/custom-button';
import PropTypes from 'prop-types';
import CustomIcon from '../icon/icon';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { AuthenticationService } from '../../services/AuthenticationService';


const AccountAdministrator = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const generateUUIDString = () => {
        return new Date().getUTCMilliseconds();
    };

    return (
        <React.Fragment>
            <CustomButton
                id="account-menu"
                style={props?.style}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {props?.text}
            </CustomButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                aria-labelledby="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '0px',
                        position: 'absolute',
                        background: '#FFFFFF',
                        border: '1px solid rgba(5, 23, 58, 0.08)',
                        boxSshadow: '0px 20px 36px rgb(16 24 40 / 8%), 0px 3px 8px rgb(16 24 40 / 5%)',
                        borderRadius: '12px',
                        width: '240px',
                    },
                }}
                MenuListProps={{
                    sx: {
                        width: '100%',
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <Divider />
                    <CustomButton
                        style={{
                            color: '#101828', background: 'transparent', display: 'flex',
                            alignItems: 'center !important', justifyContent: 'flex-start'

                        }}
                        className={'logOut'}
                        onClick={() => {
                            authenticationService.logout();
                        }}
                    >
                        <CustomIcon icon={LogoutIcon} index={generateUUIDString()} style={{ fontSize: 35 }} />
                        Logout
                    </CustomButton>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

AccountAdministrator.propTypes = {
    text: PropTypes.string,
};

export default AccountAdministrator;