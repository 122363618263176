import * as React from 'react';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import CustomIcon from '../../icon/CustomIcon';
import './tab.scss'


const tab = (props: any) => {
    return (
        <Tab
            icon={props.icon && <CustomIcon icon={props.icon} keys={props.keys} style={{ marginRight: '1rem' }} />}
            iconPosition="start"
            label={props.label}
            key={`tab-${props.index}`}
            className={'custom-tab' + (props.active === props.index ? ' activeTab' : '')}
            onChange={props.changeTab}
        />
    );
}

tab.propTypes = {
    active: PropTypes.number.isRequired,
    icon: PropTypes.any.isRequired,
    keys: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    changeTab: PropTypes.func.isRequired,
}

tab.defaultProps = {
    changeTab: () => { }
}

export default tab;