import { ExposeClient } from './../client/ExposeClient';
import { AuthenticationService } from './AuthenticationService';

export class ExposeService {

    private authenticationService: AuthenticationService;
    private exposeClient: ExposeClient;

    private _authToken: string;

    static instance: ExposeService;

    public static getInstance(): ExposeService {
        if (!ExposeService.instance) {
            ExposeService.instance = new ExposeService();
        }

        return ExposeService.instance;
    }

    constructor() {
        this.authenticationService = AuthenticationService.getInstance();
        this.exposeClient = ExposeClient.getInstance();
        this._authToken = this.authenticationService.authToken;
    }

    async getModelsUsers() {
        const loginResponse = await this.exposeClient.getModelsUsers(this._authToken);
        if (loginResponse.status && loginResponse.status === 200 && loginResponse.data) {
            return loginResponse.data;
        } else {
            return Error('Error obtaining models users');
        }
    }
}