import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Box, Pagination } from '@mui/material';
import { AuthenticationService } from '../services/AuthenticationService';
import CustomTable from '../components/account-administrator/table/custom-table';
import { PaymentService } from '../services/PaymentService';
import { ReactComponent as AddIcon } from '../assets/icons/add.svg';
import { generateBox, generateWhiteButton, standardButtonType, generateTitleTipography } from './utils';
import { v4 as uuidv4 } from 'uuid';
import { formatValue } from './utils';
import { AccountMemberHeader } from '../models/AccountMemberHeader';
import { AccountMemberRow } from '../models/AccountMemberRow';
import './pages.scss';

type PaginationStatus = {
    actual: number,
    total: number
}

const ClientsAccountManagement = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const paymentService = PaymentService.getInstance();
    const [accounts, setAccounts]: any[] = useState(null);
    const [subscriptions, setSubscriptions]: any[] = useState(null);
    const [subscriptionsUsersNames, setSubscriptionsUsersNames]: any[] = useState([]);
    const [subscriptionsPlansNames, setSubscriptionsPlansNames]: any[] = useState([]);
    const [subscriptionsTable, setSubscriptionsTable]: any[] = useState(null);
    const [structureTables, setStructureTables]: any = useState(null);
    const [paginate, setPaginate]: any = useState({ actual: 0, total: 0 });

    useEffect(() => {
        if (!accounts) {
            getAccounts();
        }
    }, [paymentService])

    const getAccounts = (page: number = 1) => {
        authenticationService.getAccounts(page).then((data) => {
            if (data && data.data && data.data.length) {
                setAccounts(data.data);
                if (paginate.total === 0) {
                    setPaginate({ actual: 1, total: Math.ceil(data.collectionSize / data.data.length) });
                }
                else if (page) {
                    setPaginate((prevPaginate: PaginationStatus) => {
                        return { ...prevPaginate, actual: page }
                    });
                }
            } else {
                resetPagination();
            }
        }).catch((err) => resetPagination());
    }

    const resetPagination = () => {
        setPaginate({ actual: 0, total: 0 });
    };

    useEffect(() => {
        if (accounts && !subscriptions) {
            let newSubscriptions: any[] = [];
            accounts.map(async (account: any) => {
                let newSubscription: any = {
                    id: account.id,
                    name: account.name,
                    planName: '',
                    user: { title: '', description: '' },
                };
                newSubscriptions.push(newSubscription);
                return getDetailsSubscription(account.id, account.ownerUserId)
                    .then((details: any) => {
                        newSubscription = { ...newSubscription, ...{ ...details } };
                    }).then(() => Promise.resolve(newSubscription));

            });
            setSubscriptions(newSubscriptions);
        }
    }, [accounts, subscriptions]);

    const getDetailsSubscription = (accountId: any, ownerUserId: any) => {
        getPlanNameSubscription(accountId);
        return getUserNameAndSubscription(accountId, ownerUserId);
    }

    const getPlanNameSubscription = async (accountId: any) => {
        let subscriptionData: any = { id: accountId };
        const paymentPromise = await paymentService.getSubscriptionsAccountActive(accountId).then((data: any) => {
            if (data && data.asset && data.asset.id) {
                subscriptionData.planName = data.asset.name;
            }
            setSubscriptionsPlansNames((oldArray: any[]) => [...oldArray, subscriptionData]);
        }).catch((error) => console.log(error));
        return paymentPromise;
    }

    const getUserNameAndSubscription = async (accountId: any, ownerUserId: any) => {
        let subscriptionData: any = { id: accountId };
        const authPayment = await authenticationService.getUserById(ownerUserId)
            .then((data: any) => {
                if (data && data.id) {
                    subscriptionData.user = {
                        title: data.firstName + ' ' + data.lastName,
                        description: data.email
                    };
                }
                setSubscriptionsUsersNames((oldArray: any[]) => [...oldArray, subscriptionData]);
            }).catch((error) => console.log(error));
        return authPayment;
    }

    useEffect(() => {
        if (subscriptions) {
            const subscriptionsDetails = subscriptionsUsersNames.map((t1: any) => ({ ...t1, ...subscriptionsPlansNames.find((t2: any) => t2.id === t1.id) }));
            const newSubscriptions = subscriptions.map((t1: any) => ({ ...t1, ...subscriptionsDetails.find((t2: any) => t2.id === t1.id) }));
            setSubscriptionsTable(newSubscriptions);
        }
    }, [subscriptions, subscriptionsUsersNames, subscriptionsPlansNames])


    useEffect(() => {
        if (subscriptionsTable && subscriptionsTable.length >= 0) {
            buildTableStructures(subscriptionsTable);
        }
    }, [subscriptionsTable]);

    useEffect(() => {
        if (paginate.actual && subscriptions && subscriptions.length) {
            buildTableStructures(subscriptionsTable);
        }
    }, [paginate])

    const buildTableStructures = (subscriptions: any) => {
        const newStructure = generateStructureTables(subscriptions);
        setStructureTables(newStructure);
    }

    const generateTitleDescriptionPage = () => {
        const title = generateTitleTipography(props.title, undefined, uuidv4());
        const description = generateTitleTipography(
            'Manage your clients’ accounts',
            {
                fontSize: '18px', fontFamily: 'ft-system-regular', color: '#667085', marginTop: '12px',
                lineHeight: '28.8px', letterSpacing: '-0.015em'
            }, uuidv4());
        const BoxComponent = generateBox(undefined, [title, description]);
        return BoxComponent;
    }

    const generateMembersMainSection = () => {
        const title = generateTitleTipography('Accounts', { fontSize: '18px' }, uuidv4());
        const titleDescription = generateTitleTipography(
            'Manage your existing clients’ accounts. ',
            { fontSize: '14px', fontFamily: 'ft-system-regular', color: '#667085', },
            uuidv4());
        const TitleDescriptionBox = generateBox({ width: '80%' }, [title, titleDescription]);
        const componentsBox = [TitleDescriptionBox];
        const buttonAction = () => { };
        const addButton: standardButtonType = {
            key: 'add-button', text: 'Add a new account', icon: AddIcon, action: buttonAction,
            className: 'disabled'
        };
        addButton.className += ' allowed';
        const addMemberButton = generateWhiteButton(addButton);
        const addMemberButtonBox = generateBox(null, [addMemberButton]);
        componentsBox.push(addMemberButtonBox);
        const boxComponentStyle = { display: 'flex', marginTop: '2em', alignItems: 'center', justifyContent: 'space-between' };
        const BoxComponent = generateBox(boxComponentStyle, componentsBox);
        return BoxComponent;
    }

    const handlePagination = (page: number) => {
        getAccounts(page);
    }

    const generateStructureTables = (subscriptions: any[]) => {
        let newStructureTables: any[] = [];
        let headers: AccountMemberHeader[] = [];
        [
            { key: 'name', title: 'Account name' },
            { key: 'user', title: 'Admin name and email' },
            { key: 'planName', title: 'Subscription plan' }
        ].forEach((header: any) =>
            headers.push(new AccountMemberHeader(header.key, header.title)));
        const dataTable: any[] = subscriptions ? generateDataTable(subscriptions) : [];
        newStructureTables.push({ headers: headers, data: dataTable, key: Math.random() });
        return newStructureTables;
    }

    const generateDataTable = (subscriptions: any[]): AccountMemberRow[] => {
        let newData: AccountMemberRow[] = [];
        subscriptions?.forEach((subscription: any) => {
            const id = subscription?.id;
            const planName = subscription?.planName;
            let accountRowData = new AccountMemberRow(id, subscription.name);
            accountRowData.planName = planName;
            const user = subscription?.user;
            accountRowData.user = user;
            newData.push(accountRowData);
        });
        return newData;
    }

    const generateTables = (structures: any[]) => {
        return structures?.map((structure: any, index: number) => {
            const emptyPlanRestrictions = buildEmptyPlanRestrictions(structure);
            return generateTable(emptyPlanRestrictions, index);
        });
    }

    const buildEmptyPlanRestrictions = (struct: any) => {
        struct.headers.forEach((header: any) => {
            if (header.key !== header.title) {
                struct.data.forEach((row: any) => {
                    if (row[header.key] === undefined) {
                        row[header.key] = '';
                    } else {
                        row[header.key] = formatValue(row[header.key]);
                    }
                });
            }
        });
        return struct;
    }

    const generateTable = (structure: any, index: number) => {
        const tableKey = 'table-' + structure.key + '-' + index;
        return (<Box
            sx={{ display: 'flex', mt: '15px' }}
            key={`box-table-${structure?.key}`}>
            <CustomTable
                class='clientAccountManagement'
                visibleHeaders={structure?.visibleHeaders}
                headers={structure?.headers}
                data={structure?.data}
                keys={tableKey}
            />
        </Box>);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {generateTitleDescriptionPage()}
            <Box>
                {generateMembersMainSection()}
                {structureTables && generateTables(structureTables)}
            </Box >
            <Box sx={{ marginTop: '1.5em', alignSelf: 'flex-end' }}>
                <Pagination
                    onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                        setPaginate((prevPaginate: PaginationStatus) => {
                            return { ...prevPaginate, actual: page }
                        });
                        setAccounts(null);
                        setSubscriptions(null);
                        handlePagination(page);
                    }}
                    className='pagination' count={paginate.total} variant='outlined' shape='rounded' />
            </Box>
        </Box >
    );
}

ClientsAccountManagement.propTypes = {
    title: PropTypes.string.isRequired,
}

export default ClientsAccountManagement;