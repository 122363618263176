import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import './password-modal.scss';
import { v4 as uuidv4 } from 'uuid';
import InputPassword from '../input/InputPassword';
import { CustomButton } from '../styled-components/custom-button';
import { AuthenticationService } from '../../services/AuthenticationService';


const classes = {
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 24px 24px 24px',
        gap: '24px',
        width: '498px',
        userSelect: 'none',
        background: '#FFFFFF',
        borderRadius: '40px',
    }
};

const CustomDiv = (props: any) => <div className='CustomDiv' key={props?.customKey} style={props.style}>{props.children}</div>;

const TitleTypograpy = styled(Typography)<TypographyProps>(() => ({
    fontFamily: 'ft-system-bold',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '42px',
    lineHeight: '130%',
    textAlign: 'center',
    letterSpacing: '-0.03em',
    color: '#000000',
    width: '350px',
    userSelect: 'none',
}));

const ContentTypograpy = styled(Typography)<TypographyProps>(() => ({
    width: '430px',
    fontFamily: 'ft-system-regular',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.02em',
    color: '#667085',
    flex: 'none',
    flexGrow: 0,
    userSelect: 'none',
}));

const PasswordModal = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        props.changeOpen(open);
    }, [open]);

    const [disabledButton, setDisabledButton] = useState(true);
    const [oldPass, setOldPass] = useState<string>();
    const [newPass, setNewPass] = useState<string>();
    const [confirmNewPass, setConfirmNewPass] = useState<string>();

    useEffect(() => {
        if (oldPass && newPass && confirmNewPass
            && newPass === confirmNewPass
            && newPass.length >= 8 && confirmNewPass.length >= 8) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [oldPass, newPass, confirmNewPass]);

    const inputsPassword = [
        { id: 'oldPass', title: 'Current password', key: 'input-old-password', method: setOldPass },
        {
            id: 'newPass', title: 'New password', key: 'input-new-password', method: setNewPass,
            suggestion: 'Your new password must be more than 8 characters.', options: { minLength: 8 }
        },
        {
            id: 'confirmNewPass', title: 'Confirm new password', key: 'input-confirm-new-password', method: setConfirmNewPass,
            suggestion: 'Your new password must be more than 8 characters.', options: { minLength: 8 }
        },
    ];

    const generateInputsPasswords = () => {
        return inputsPassword.map((input) => generateInputBlock(input));
    }

    const generateInputBlock = (input: any) => {
        const key1 = input.key + '-1';
        const key2 = input.key + '-2';
        const customStyleDiv = { alignItems: 'flex-start', gap: '4px !important' }
        return (<CustomDiv style={customStyleDiv} key={key1}>
            <CustomDiv style={{ ...{ customStyleDiv }, marginTop: '10px' }} key={key2}>
                {generateContentTypograpy(input?.title, '5px', 0)}
                {generateInput(input)}
                {input?.suggestion && generateContentTypograpy(input?.suggestion, 0, '5px')}
            </CustomDiv>
        </CustomDiv>)
    }

    const generateContentTypograpy = (title: any, marginBottom: any, marginTop: any) => {
        return <ContentTypograpy sx={{ textAlign: 'left', fontSize: '14px', lineHeight: '20px', mb: marginBottom, mt: marginTop }}>
            {title}
        </ContentTypograpy>
    }

    const generateInput = (input: any) => {
        return <InputPassword keys={input?.key} changeValue={input.method} {...input?.options} />;
    }

    const generateModalButton = () => {
        return <CustomDiv
            key={'modal-button'}
            style={{ marginBottom: "1rem", marginTop: "1rem", gap: '4px !important', width: '87%' }}
        >
            <CustomButton
                key={`modal-button`}
                onClick={() => {
                    const response = authenticationService.changePassword(oldPass, newPass, confirmNewPass);
                    response.then(() => setOpen(false));
                }}
                style={{ width: '100%' }}
                disabled={disabledButton}
                className={disabledButton ? 'disabled' : null}
            >
                Change password
            </CustomButton>
        </CustomDiv>
    }

    const generateKey = () => { return uuidv4(); }

    return (
        <Modal
            className="centerModal"
            disablePortal={true}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
            disableEscapeKeyDown={true}
        >
            <Box sx={classes.box}>
                <CustomDiv style={{ marginBottom: "1rem", alignItems: 'flex-start', gap: '4px !important' }} key={generateKey()}>
                    <TitleTypograpy sx={{ paddingBottom: '0px !important', fontSize: '18px', textAlign: 'left', lineHeight: '28px' }}>
                        Change password
                    </TitleTypograpy>
                    <ContentTypograpy sx={{ textAlign: 'left', fontSize: '14px', lineHeight: '20px' }}>
                        Please enter your current password to change your password.
                    </ContentTypograpy>
                </CustomDiv>
                {generateInputsPasswords()}
                <CustomDiv
                    key={'forgot-password-div'}
                    style={{ gap: '4px !important', width: '87%' }}
                >
                    <CustomButton
                        key={`forgot-password-button`}
                        onClick={() => {
                            props.onChange && props.onChange('forgot-password');
                        }}
                        className='forgotButton'
                    >
                        Forgot password?
                    </CustomButton>
                </CustomDiv>
                {generateModalButton()}
            </Box>
        </Modal >
    );
}

PasswordModal.propTypes = {
    open: PropTypes.bool,
    onChange: PropTypes.func,
    changeOpen: PropTypes.func,
}

PasswordModal.defaultProps = {
    open: false,
    changeOpen: () => false,
    onChange: () => false,
}

export default PasswordModal;