import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './custom-table.scss';
import PropTypes from 'prop-types';
import { TitleTypography } from '../../styled-components/title-typography';
import { v4 as uuidv4 } from 'uuid';
import ComponentsRows from './components-table-row';
import { StyledTableRow, StyledTableCell } from './commons';
import { CustomButton } from '../../styled-components/custom-button';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { Box } from '@mui/system';

const CustomTable = (props: any) => {

    const [headerCells, setHeaderCells] = useState<any[]>();

    useEffect(() => {
        if (props.headers && props.headers.length > 0) {
            generateHeaderCells(props.headers);
        } else {
            generateHeaderCells([]);
        }
    }, [props.headers, props.editHeaderId]);

    const generateHeaderCells = (headers: any[]) => {
        let newHeaders = headers.map((header: any, index: number) => generateHeaderCell(header, index));
        if (props.editRows || props.deleteRows) {
            const actionHeader = generateHeaderCell({ key: 'actions', title: 'Action' });
            newHeaders.push(actionHeader);
        }
        setHeaderCells(newHeaders);
    };

    const generateHeaderCell = (cell: any, index?: number) => {
        let editButton = null;
        let title = cell?.title;
        if ((props.editHeaders || cell.key === 'new-plan') && index && index !== 0) {
            const button = {
                icon: EditIcon, key: 'edit-button-header-' + cell.key, action: () => props?.editHeaderAction(cell.key),
                disabled: props?.editHeaderId ? true : false, className: 'allowed ' + (props?.editHeaderId ? 'disabled' : '')
            };
            if (cell.key !== 'new-plan') {
                editButton = generateButton(button);
            }
            if (cell.key === props.editHeaderId && cell[props.editHeaderId] && cell[props.editHeaderId].component) {
                title = cell[props.editHeaderId].component;
            } else {
                title = (<div style={{ display: 'flex', alignItems: 'center' }}>
                    {cell?.title}
                    {editButton}
                </div>);
            }
        }
        return (<StyledTableCell align="left" style={cell?.style}>
            {title}
            {cell?.icon && generateSvg(cell.icon)}
        </StyledTableCell>)
    }

    const generateSvg = (Icon: any) => {
        return (<Icon style={{ marginLeft: '5px' }}></Icon>);
    }

    const generateRows = (data: any[]) => {
        const rows = data.map((row: any) => generateRow(row));
        return rows;
    }

    const generateRow = (row: any) => {
        if (row.components) {
            return ComponentsRows(row);
        }
        const cells = generateCells(row);
        if (props.editRows || props.deleteRows) {
            const actionButtons = generateActionsButtonsRows(row);
            cells.push(actionButtons);
        }
        return (<StyledTableRow>
            {cells}
        </StyledTableRow>);
    }

    const generateActionsButtonsRows = (row: any) => {
        const deleteAction: any = () => { props.deleteAction && props.deleteAction(row.id) };
        const deleteRow = { key: row.key, action: deleteAction, icon: DeleteIcon };
        const editRow = { key: row.key, action: undefined, icon: EditIcon };
        if (row && row.role && row.role.toLowerCase() !== 'admin') {
            if (props.editRows && props.deleteRows) {
                const buttons = [editRow, deleteRow].map((button: any) => generateButton(button));
                const buttonsCells = generateButtonCell(buttons);
                return buttonsCells;
            } else if (props.editRows) {
                const editButton = generateButtonAction(editRow);
                return editButton;
            } else if (props.deleteRows) {
                const deleteButton = generateButtonAction(deleteRow);
                return deleteButton;
            }
        }
        return <StyledTableCell></StyledTableCell>;
    }

    const generateButtonAction = (buttonRow: any) => {
        const button = generateButton(buttonRow);
        const buttonCell = generateButtonCell(button);
        return buttonCell;
    }

    const colors = ['#101828', '#667085'];

    const generateCells = (row: any) => {
        const keys = Object.keys(row)?.filter((key: any) => {
            const headerKey = props.headers?.find((hk: any) => hk.key === key);
            return headerKey && row[key] !== undefined && row[key] !== null;
        });
        const newOrderKeys: any = orderRowKeysFromHeaders(keys);
        const cells = newOrderKeys.map((key: any) => {
            if (typeof row[key] !== 'string' && typeof row[key] !== 'number') {
                let cellContent: any = <></>;
                if (row[key].visible === false) {
                    cellContent = <></>;
                } else if (row[key]?.component) {
                    cellContent = row[key].component;
                } else {
                    const objectKeys = Object.keys(row[key]);
                    cellContent = objectKeys.map((objKey: string, index: any) => {
                        return generateTitleTipography(row[key][objKey],
                            {
                                fontSize: '14px', fontFamily: 'ft-system-regular', color: colors[index % 2],
                                lineHeight: '20px', letterSpacing: '-1%'
                            },
                            uuidv4());
                    });
                }
                return generateCell(cellContent);
            } else {
                return generateCell(row[key]);
            }
        });
        return cells;
    }

    const orderRowKeysFromHeaders = (keys: any[]) => {
        const headersKeys = props.headers.map((header: any) => header.key);
        const newOrderKeys: any[] = [];
        headersKeys.forEach((headerKey: any) => {
            const orderKey = keys.find((key: any) => key === headerKey);
            if (orderKey) {
                newOrderKeys.push(orderKey);
            }
        });
        return newOrderKeys;
    }

    const generateCell = (content: any) => {
        return (<StyledTableCell component="th" scope="row">
            {content}
        </StyledTableCell>);
    }

    const generateTitleTipography = (title: string, style?: any, key?: any) => {
        return <TitleTypography
            key={key}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '40px', ...{ ...style } }} >
            {title}
        </TitleTypography>
    }

    const generateButtonCell = (buttons: any) => {
        return (<StyledTableCell component="th" scope="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {buttons}
        </StyledTableCell>);
    }

    const generateButton = (button: any) => {
        return <CustomButton
            key={button?.key}
            disabled={button?.disabled}
            className={`btnWhite transparent ${button?.className}`}
            style={button?.style}
            onClick={() => {
                button?.action && button?.action();
            }}
        >
            {button?.text}
            {button?.icon && generateSvg(button.icon)}
        </CustomButton>;
    }

    const generateHeadersWithoutText = (headerCell: any) => {
        return headerCell.map((e: any, index: number) => {
            if (props.dontPrintHeaders && index === 0) {
                return generateHeaderCell(props.dontPrintHeaders);
            } else {
                return <StyledTableCell align="left"></StyledTableCell>
            }
        })
    }

    return (
        <TableContainer component={Paper} className={`${props.class ?? ''} CustomTable`} key={props?.keys}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                {
                    props.visibleHeaders &&
                        headerCells
                        ? <TableHead>
                            <TableRow>{props.dontPrintHeaders ? generateHeadersWithoutText(headerCells) : headerCells}</TableRow>
                        </TableHead>
                        : null
                }
                <TableBody>
                    {props.data ? generateRows(props.data) : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

CustomTable.propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array,
    editRows: PropTypes.bool,
    editAction: PropTypes.func,
    deleteRows: PropTypes.bool,
    deleteAction: PropTypes.func,
    class: PropTypes.string,
    dontPrintHeaders: PropTypes.any,
    keys: PropTypes.string,
    visibleHeaders: PropTypes.bool,
    editHeaders: PropTypes.bool,
    editHeaderAction: PropTypes.func,
    editHeaderId: PropTypes.string,
}

CustomTable.defaultProps = {
    editRows: false,
    editAction: () => undefined,
    deleteRows: false,
    deleteAction: () => undefined,
    visibleHeaders: true,
    editHeaders: false,
    editHeaderAction: () => undefined,
}

export default CustomTable;
