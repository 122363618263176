import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import './custom-modal.scss';
import { v4 as uuidv4 } from 'uuid';
import { CustomButton } from '../styled-components/custom-button';
import { AuthenticationService } from '../../services/AuthenticationService';
import Input from '../input/Input';
import { TitleTypography } from '../styled-components/title-typography';


const classes = {
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 24px 24px 24px',
        gap: '24px',
        width: '498px',
        userSelect: 'none',
        background: '#FFFFFF',
        borderRadius: '40px',
    }
};

const CustomDiv = (props: any) => <div className='CustomDiv' key={props?.customKey} style={props.style}>{props.children}</div>;

const TitleTypograpy = styled(Typography)<TypographyProps>(() => ({
    fontFamily: 'ft-system-bold',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '42px',
    lineHeight: '130%',
    textAlign: 'center',
    letterSpacing: '-0.03em',
    color: '#000000',
    width: '350px',
    userSelect: 'none',
}));

const ContentTypograpy = styled(Typography)<TypographyProps>(() => ({
    width: '430px',
    fontFamily: 'ft-system-regular',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.02em',
    color: '#667085',
    flex: 'none',
    flexGrow: 0,
    userSelect: 'none',
}));

const CustomModal = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const [open, setOpen] = useState(props.open);
    const handleClose = () => setOpen(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        props.changeOpen(open);
    }, [open]);

    const [disabledButton, setDisabledButton] = useState(true);
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();

    useEffect(() => {
        setErrorMessage(undefined);
        if (firstName && lastName && email) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [firstName, lastName, email]);

    const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(_[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    const INPUTS = [
        { id: 'firstName', title: 'First Name', key: 'input-first-name', method: setFirstName },
        { id: 'lastName', title: 'Last Name', key: 'input-last-name', method: setLastName },
        {
            id: 'email', title: 'Email', key: 'input-email', method: setEmail,
            options: {
                regex: EMAIL_REGEX,
                errorMessage: 'This email is not valid. Please enter a new, valid email address.'
            }
        }
    ];

    const generateInputsPasswords = () => {
        return INPUTS.map((input) => generateInputBlock(input));
    }

    const generateInputBlock = (input: any) => {
        const key1 = input.key + '-1';
        const key2 = input.key + '-2';
        const customStyleDiv = { alignItems: 'flex-start', gap: '4px !important' }
        return (<CustomDiv style={customStyleDiv} key={key1}>
            <CustomDiv style={{ ...{ customStyleDiv }, marginTop: '10px' }} key={key2}>
                {generateContentTypograpy(input?.title, '5px', 0)}
                {generateInput(input)}
                {input?.suggestion && generateContentTypograpy(input?.suggestion, 0, '5px')}
            </CustomDiv>
        </CustomDiv>)
    }

    const generateContentTypograpy = (title: any, marginBottom: any, marginTop: any) => {
        return <ContentTypograpy sx={{ textAlign: 'left', fontSize: '14px', lineHeight: '20px', mb: marginBottom, mt: marginTop }}>
            {title}
        </ContentTypograpy>
    }

    const generateInput = (input: any) => {
        return <Input keys={input?.key} onChange={input.method} {...input?.options} />;
    }

    const generateModalButton = () => {
        return <CustomDiv
            key={'modal-button'}
            style={{ marginBottom: "1rem", marginTop: "1rem", gap: '4px !important', width: '87%' }}
        >
            <CustomButton
                key={`modal-button`}
                onClick={() => {
                    if (firstName && lastName && email) {
                        const response = authenticationService.addNewUserAccount(firstName, lastName, email);
                        response.then(() => {
                            props.onChange && props.onChange('success');
                        }).catch((error: any) => {
                            const errorMessage = (error?.response?.data?.message !== ''
                                ? error?.response?.data?.message : error.message);
                            setErrorMessage(errorMessage);
                            setDisabledButton(true);
                        });
                    }
                }}
                style={{ width: '100%' }}
                disabled={disabledButton}
                className={disabledButton ? 'disabled' : null}
            >
                Save
            </CustomButton>
        </CustomDiv>
    }

    const generateKey = () => { return uuidv4(); }

    const generateErrorMessage = (errorMessage: string) => {
        return <TitleTypography
            style={{
                display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                fontSize: '16px', color: 'red', fontFamily: 'ft-system-regular', marginTop: '5px'
            }} >
            {errorMessage}
        </TitleTypography>
    }

    return (
        <Modal
            className="centerModal"
            disablePortal={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={classes.box}>
                <CustomDiv style={{ marginBottom: "1rem", alignItems: 'flex-start', gap: '4px !important', width: '430px' }} key={generateKey()}>
                    <TitleTypograpy sx={{ paddingBottom: '0px !important', fontSize: '18px', textAlign: 'left', lineHeight: '28px' }}>
                        Add new team member
                    </TitleTypograpy>
                </CustomDiv>
                {generateInputsPasswords()}
                {generateModalButton()}
                {errorMessage && generateErrorMessage(errorMessage)}
            </Box>
        </Modal >
    );
}

CustomModal.propTypes = {
    open: PropTypes.bool,
    onChange: PropTypes.func,
    changeOpen: PropTypes.func,
}

CustomModal.defaultProps = {
    open: false,
    changeOpen: () => false,
}

export default CustomModal;