import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import SettingsIcon from '../../assets/icons/settings.svg';
import PaymentsIcon from '../../assets/icons/payments.svg';
import TeamIcon from '../../assets/icons/team.svg';
import Tab from '../commons/tabs/tab';
import TabPanel from '../commons/tab-panel/tab-panel';
import { TitleTypography } from '../styled-components/title-typography';
import Account from '../../pages/account';
import Payment from '../../pages/payment';
import PlanManagement from '../../pages/planManagement';
import './settings.scss';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationService } from '../../services/AuthenticationService';
import ClientsAccountManagement from '../../pages/clientsAccountManagement';
import Management from '../../pages/management/management';

const ACCOUNT = 'Account';
const PAYMENT = 'Payment & subscription';
const MANAGEMENT = 'Team management';
const ACCOUNT_SETTINGS_TEXT = 'Account settings';
const PLAN_MANAGEMENT = 'AIZI Plans Management';
const CLIENTS_ACCOUNT_MANAGEMENT = 'Clients’ Account management';

const Settings = (props: any) => {
    const authenticationService = AuthenticationService.getInstance();
    const account = authenticationService.accountInfo;
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        const editMode = localStorage.getItem('editMode');
        if (editMode !== 'true') {
            setValue(newValue);
        }
    };

    const SETTINGS_PANELS: any = [
        { index: 0, text: ACCOUNT, icon: SettingsIcon, component: <Account title={ACCOUNT} /> },
        { index: 1, text: PAYMENT, icon: PaymentsIcon, component: <Payment title={PAYMENT} /> },
        { index: 2, text: MANAGEMENT, icon: TeamIcon, component: <Management title={MANAGEMENT} /> },
        { index: 3, text: PLAN_MANAGEMENT, role: 'admin', icon: PaymentsIcon, component: <PlanManagement title={PLAN_MANAGEMENT} /> },
        { index: 4, text: CLIENTS_ACCOUNT_MANAGEMENT, role: 'admin', icon: TeamIcon, component: <ClientsAccountManagement title={CLIENTS_ACCOUNT_MANAGEMENT} /> }
    ];

    const buildTab = (tab: any) => {
        return <Tab
            active={value}
            icon={tab.icon} label={tab.text}
            key={uuidv4()}
            index={tab.index} keys={`custom-tab-${tab.index}`}
            changeTab={(e) => handleChange(e, tab.index)} />
    }

    const generateTabs = () => {
        return SETTINGS_PANELS.reduce((initial: any, tab: any) => {
            if (!account && (tab.index === 1 || tab.index === 2)) {
                return initial;
            }
            if (authenticationService.userisAdmin) {
                initial.push(buildTab(tab));
            } else if (!tab.role) {
                initial.push(buildTab(tab));
            }
            return initial;
        }, []);
    };

    const generateTabPanels = () => {
        return SETTINGS_PANELS.reduce((initial: any, tabPanel: any) => {
            if (tabPanel.index === value) {
                initial.push(
                    <TabPanel
                        componentPanel={tabPanel.component}
                        value={value}
                        index={tabPanel.index}
                        key={`tab-panel-${tabPanel.index}`}
                    />
                );
            }
            return initial;
        }, []);
    };

    return (
        <Box className='settings'>
            <Box className='settingsTabs'>
                <TitleTypography className='settingsTitle' >
                    {ACCOUNT_SETTINGS_TEXT}
                </TitleTypography>
                <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={value}
                    onChange={handleChange}
                    aria-label='Vertical tabs example'
                    TabIndicatorProps={{
                        sx: { backgroundColor: 'transparent !important' }
                    }}
                >
                    {generateTabs()}
                </Tabs>
            </Box>
            {generateTabPanels()}
        </Box>
    );
};

export default Settings;