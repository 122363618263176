import { Box } from "@mui/system";
import { CustomButton } from "../components/styled-components/custom-button";
import { TitleTypography } from "../components/styled-components/title-typography";
import { v4 as uuidv4 } from 'uuid';
import Input from "../components/input/Input";
import StandardModal from '../components/modal/standard-modal';

export const formatISOtoDate = (date: string) => {
    if (!date) {
        return false;
    }
    const newdate = new Date(date);
    if (newdate) {
        let month = '' + (newdate.getMonth() + 1);
        if (month.length === 1) {
            month = '0' + month;
        }
        const year = ('' + newdate.getFullYear()).slice(2);
        return month + '/' + year;
    }
}

export const generateBox = (style: any, BoxComponent: any) => {
    return (<Box sx={style} key={uuidv4()}>
        {BoxComponent}
    </Box>);
}

export const generateTitleTipography = (title: string, style?: any, key?: any) => {
    return (<TitleTypography
        key={key}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '40px', ...{ ...style } }} >
        {title}
    </TitleTypography>);
}

export type standardButtonType = {
    key?: string,
    style?: object,
    className?: string,
    icon?: any,
    text: string,
    action: Function
}

export const generateWhiteButton = (button: standardButtonType, disableButton?: any) => {
    // TODO: Refactor this function for usage in map functions
    return <CustomButton
        key={button?.key}
        style={button?.style ?? {}}
        onClick={() => {
            if (disableButton !== true) {
                button?.action && button?.action();
            }
        }}
        className={`btnWhite ${button?.className ?? ''}`}
    >
        {button?.icon && generateSvg(button.icon)}
        {button?.text}
    </CustomButton>;
}

export type generateStandardModal = { open?: boolean, title: string, message: string, buttons?: standardButtonType[] }

export const generateModal = (modalData: generateStandardModal) => <StandardModal
    open={modalData?.open}
    title={modalData.title}
    message={modalData.message}
    buttons={modalData?.buttons}
/>

const generateSvg = (Icon: any) => {
    return (<Icon />);
}

export const formatValue = (value: any) => {
    if (typeof value === 'string' && Number(value)) {
        value = Number(value);
    }
    if (typeof value === 'string' && !Number(value) && (value === 'true' || value === 'false')) {
        value = value === 'true';
    }
    if (typeof value === 'boolean') {
        if (value) {
            return 'Yes';
        }
        return 'No';
    } else if (typeof value === 'number') {
        if (value < 0) {
            return 'Unlimited';
        }
    }
    return value;
}

export const generateInput = (refData?: any, disabled?: any, type?: any, style?: any, placeholder?: any, value?: any) => {
    return <Input
        ref={refData}
        value={refData?.current?.value}
        defaultValue={value}
        key={refData?.current?.key}
        disabled={disabled}
        type={type ?? 'text'}
        style={style}
        placeholder={placeholder}
    />
}