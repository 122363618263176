import { Restriction } from './Restriction';
export class Account {
    id: string;
    name: string;
    ownerUserId: string;
    companyName: string;
    companyWebsite: string;
    companyIndustry: string;
    companySubsidiaries: string;
    restrictions: Restriction[];

    constructor(id: string, name: string, ownerUserId: string, companyName: string, companyWebsite: string, companyIndustry: string, companySubsidiaries: string, restrictions: any[]) {
        this.id = id;
        this.name = name;
        this.ownerUserId = ownerUserId;
        this.companyName = companyName;
        this.companyWebsite = companyWebsite;
        this.companyIndustry = companyIndustry;
        this.companySubsidiaries = companySubsidiaries;
        this.restrictions = restrictions;
    }
}