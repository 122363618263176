import React, { useState } from 'react'
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

const CustomIcon = (props: any) => {

    const [index, setIndex] = useState(props?.index ?? Math.random())

    return (
        <IconButton key={`icon-${index}`} style={props?.style}>
            <props.icon sx={{
                color: 'red',
                backgroundColor: 'red',
                width:'24px',
                height:'24px',
                '&:hover': { color: 'blue' }
            }} />
        </IconButton>
    )
};

CustomIcon.propTypes = {
    icon: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.any
}

CustomIcon.defaultProps = {}

export default CustomIcon