import axios from 'axios';

export class ExposeClient {

    baseUrl: string;

    static instance: ExposeClient;

    public static getInstance(): ExposeClient {
        if (!ExposeClient.instance) {
            ExposeClient.instance = new ExposeClient();
        }

        return ExposeClient.instance;
    }

    constructor() {
        const baseUrl: string = String(process.env.REACT_APP_EXPOSE_API_BASE_URL) +
            String(process.env.REACT_APP_EXPOSE_API_PREFIX_URI) + '/expose';
        this.baseUrl = baseUrl;
    }

    generateHeaders(token: string) {
        return {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        };
    }

    async getModelsUsers(token: string) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + '/models/account/users/collectionSize', { headers });
        return response;
    }
}